import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Lightbox } from 'ngx-lightbox';
import { DoctorServices } from 'src/app/services/doctorServices.service';

export interface ServiceTimetable {
  dayOfWeek: string,
  startTime: string
};

@Component({
  selector: 'app-new-services',
  templateUrl: './new-services.component.html',
  styleUrls: ['./new-services.component.css']
})
export class NewServicesComponent implements OnInit {

  timeTable: ServiceTimetable[] = [];
  fasceOrariGiorni = [
    { time: "Unico turno/Continuato" }, //Orario Continuato
    { time: "A turni" }, // a turni
  ];
  fasceOrarioLunedi: string = "Unico turno/Continuato";
  fasceOrarioMartedi: string = "Unico turno/Continuato";
  fasceOrarioMercoledi: string = "Unico turno/Continuato";
  fasceOrarioGiovedi: string = "Unico turno/Continuato";
  fasceOrarioVenerdi: string = "Unico turno/Continuato";
  fasceOrarioSabato: string = "Unico turno/Continuato";
  fasceOrarioDomenica: string = "Unico turno/Continuato";
  description: string;
  serviceName;
  specialistName;
  specialistSurname;
  price = 0;
  idShop;
  file;
  lastFile;
  _albums: Array<any> = [];
  fileFlag: boolean = false;
  durationTime: any;
  isTimeValidLunedi: boolean = true;
  isTimeValidMartedi: boolean = true;
  isTimeValidMercoledi: boolean = true;
  isTimeValidGiovedi: boolean = true;
  isTimeValidVenerdi: boolean = true;
  isTimeValidSabato: boolean = true;
  isTimeValidDomenica: boolean = true;
  selectedTimeLunedi: any = {dayOfWeek: 'LUN',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeMartedi: any = {dayOfWeek: 'MAR',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeMercoledi: any = {dayOfWeek: 'MER',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeGiovedi: any = {dayOfWeek: 'GIO',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeVenerdi: any = {dayOfWeek: 'VEN',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeSabato: any = {dayOfWeek: 'SAB',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};
  selectedTimeDomenica: any = {dayOfWeek: 'DOM',startTimeMorning: null,endTimeMorning: null,startTimeAfternoon : null,endTimeAfternoon : null};

  sottoServiziAggiunti: {
    name: '',
    description: '',
    cost: 0,
    serviceType: "INSTRUMENTAL_CHECKUP"
  }[] = [];
  

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private _lightbox: Lightbox,
    private doctorService: DoctorServices) {
  };

  ngOnInit() {
    this.idShop = this.route.snapshot.paramMap.get("id");
    const album = {
      src: "",
    }
    this._albums.push(album);
  };

  onSubmit() {
    this.spinner.show();
    let serviceBody = {
      name: this.serviceName,
      operatorName: this.specialistName,
      operatorSurname: this.specialistSurname,
      description: this.description,
      minutesAverageDuration: this.durationTime,
      cost: 0,
      serviceType: 1,
      serviceInShopAvailabilityToSaveRequest: null,
      subServiceInShopToSaveRequestList: null
    };
    let numberTime = [];

    this.selectedTimeLunedi.startTimeMorning != null && this.selectedTimeLunedi.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeLunedi.startTimeMorning,this.selectedTimeLunedi.endTimeMorning,this.durationTime,this.selectedTimeLunedi.endTimeMorning,'LUN'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeLunedi.startTimeAfternoon != null && this.selectedTimeLunedi.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeLunedi.startTimeAfternoon,this.selectedTimeLunedi.endTimeAfternoon,this.durationTime,'21:00','LUN'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeMartedi.startTimeMorning != null && this.selectedTimeMartedi.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeMartedi.startTimeMorning,this.selectedTimeMartedi.endTimeMorning,this.durationTime,this.selectedTimeMartedi.endTimeMorning,'MAR'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeMartedi.startTimeAfternoon != null && this.selectedTimeMartedi.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeMartedi.startTimeAfternoon,this.selectedTimeMartedi.endTimeAfternoon,this.durationTime,'21:00','MAR'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeMercoledi.startTimeMorning != null && this.selectedTimeMercoledi.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeMercoledi.startTimeMorning,this.selectedTimeMercoledi.endTimeMorning,this.durationTime,this.selectedTimeMercoledi.endTimeMorning,'MER'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeMercoledi.startTimeAfternoon != null && this.selectedTimeMartedi.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeMercoledi.startTimeAfternoon,this.selectedTimeMercoledi.endTimeAfternoon,this.durationTime,'21:00','MER'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeGiovedi.startTimeMorning != null && this.selectedTimeGiovedi.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeGiovedi.startTimeMorning,this.selectedTimeGiovedi.endTimeMorning,this.durationTime,this.selectedTimeGiovedi.endTimeMorning,'GIO'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeGiovedi.startTimeAfternoon != null && this.selectedTimeGiovedi.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeGiovedi.startTimeAfternoon,this.selectedTimeGiovedi.endTimeAfternoon,this.durationTime,'21:00','GIO'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeVenerdi.startTimeMorning != null && this.selectedTimeVenerdi.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeVenerdi.startTimeMorning,this.selectedTimeVenerdi.endTimeMorning,this.durationTime,this.selectedTimeVenerdi.endTimeMorning,'VEN'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeVenerdi.startTimeAfternoon != null && this.selectedTimeVenerdi.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeVenerdi.startTimeAfternoon,this.selectedTimeVenerdi.endTimeAfternoon,this.durationTime,'21:00','VEN'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeSabato.startTimeMorning != null && this.selectedTimeSabato.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeSabato.startTimeMorning,this.selectedTimeSabato.endTimeMorning,this.durationTime,this.selectedTimeSabato.endTimeMorning,'SAB'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeSabato.startTimeAfternoon != null && this.selectedTimeSabato.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeSabato.startTimeAfternoon,this.selectedTimeSabato.endTimeAfternoon,this.durationTime,'21:00','SAB'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeDomenica.startTimeMorning != null && this.selectedTimeDomenica.endTimeMorning != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeDomenica.startTimeMorning,this.selectedTimeDomenica.endTimeMorning,this.durationTime,this.selectedTimeDomenica.endTimeMorning,'DOM'),this.timeTable.push(...numberTime)) : null;

    this.selectedTimeDomenica.startTimeAfternoon != null && this.selectedTimeDomenica.endTimeAfternoon != null ? 
    (numberTime = this.generateValidTimeSlots(this.selectedTimeDomenica.startTimeAfternoon,this.selectedTimeDomenica.endTimeAfternoon,this.durationTime,'21:00','DOM'),this.timeTable.push(...numberTime)) : null;
    
    serviceBody.serviceInShopAvailabilityToSaveRequest = this.timeTable;
    serviceBody.subServiceInShopToSaveRequestList = this.sottoServiziAggiunti;

    this.doctorService.addDoctorService(serviceBody, this.file).then((x) => {
      this.router.navigate(["/allServices/" + this.idShop]);
      alert("Servizio aggiunto con successo!");
      this.spinner.hide();
    }).catch(err => {
      this.spinner.hide()
    })
  };

  onTimeSlotChange(event: any) {
    this.durationTime = parseInt(event.target.value);
  };

  upload(fileUploaded) {
    /*     let stringToCompare = fileUploaded.files[0].name.split(".")[1]; */
    const file: File = fileUploaded.files[0];


    if (fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "jpg" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "mp4" || fileUploaded.files[0].name.split(".")[fileUploaded.files[0].name.split(".").length - 1] == "jpeg") {
      this.file = file;
      this.lastFile = file;

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this._albums[0].src = reader.result;
      }

    }
    else {
      alert("Errore con l'upload dei file. Il Sistema gestisce i seguenti formati: .jpg, .jpeg")
      this.fileFlag = false;
    }

    this.fileFlag = this.file != undefined ? true : false

  };

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  };


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  };

  checkSottoServiziAggiunti(): boolean {
    return this.sottoServiziAggiunti.some(sottoServizi => sottoServizi.name === '');
  };

  checkTime(endTimeMorning: string, startTimeAfternoon: string): boolean {
    // Funzione per verificare se l'orario è compreso tra le 07:00 e le 21:00
    const isTimeValid = (time: string): boolean => {
      const [hours, minutes] = time.split(':').map(part => parseInt(part));
      const totalMinutes = hours * 60 + minutes;
      const startLimit = 7 * 60; // 07:00 in minuti
      const endLimit = 21 * 60;   // 21:00 in minuti
      return totalMinutes >= startLimit && totalMinutes <= endLimit;
    };
  
    // Verifica se gli orari sono definiti e validi
    if (startTimeAfternoon && endTimeMorning) {
      // Controlla se entrambi gli orari sono validi
      if (!isTimeValid(startTimeAfternoon) || !isTimeValid(endTimeMorning)) {
        return false; // Restituisce false se uno degli orari non è valido
      }
  
      // Converte gli orari in minuti totali
      const selectedTime = startTimeAfternoon.split(':');
      const previousTime = endTimeMorning.split(':');
      const selectedMinutes = parseInt(selectedTime[0]) * 60 + parseInt(selectedTime[1]);
      const previousMinutes = parseInt(previousTime[0]) * 60 + parseInt(previousTime[1]);
  
      // Controlla se l'orario selezionato è maggiore di quello precedente
      return selectedMinutes > previousMinutes;
    }
    
    return false; // Restituisce false se gli orari non sono definiti
  }

  generateValidTimeSlots(
    startTime: string, 
    endTime: string, 
    duration: number, 
    limitTime: string,
    nameDay:string
  ) {
    const validTimeSlots = [];
    
    // Converti gli orari di inizio e fine in ore e minuti
    const [startHour, startMinute] = startTime.split(':').map(part => parseInt(part));
    const [endHour, endMinute] = endTime.split(':').map(part => parseInt(part));
    const limitHour = parseInt(limitTime.split(':')[0]);
    const limitMinute = parseInt(limitTime.split(':')[1]);
    
    // Genera i time slots
    this.generateTimeSlots(startHour, startMinute, endHour, endMinute, duration).forEach(elem => {
      // Converti l'orario 'HH:MM' in minuti totali
      const [hours, minutes] = elem.split(':').map(part => parseInt(part));
      const totalMinutes = hours * 60 + minutes;
  
      // Durata massima consentita in minuti
      const limitTotalMinutes = limitHour * 60 + limitMinute;
  
      // Controllo che l'orario più la durata della visita non superi il limite
      //if (totalMinutes + duration <= limitTotalMinutes) {
        validTimeSlots.push({ dayOfWeek: nameDay, startTime: elem });
      //}
    });
  
    return validTimeSlots;
  }

  generateTimeSlots = (startHour: number, startMinute: number, endHour: number, endMinute: number, increment: number) => {
    const timeSlots = [];
    
    // Convertiamo l'orario di partenza e di fine in minuti totali
    let currentTime = startHour * 60 + startMinute; // Ora di partenza in minuti
    const endTime = endHour * 60 + endMinute; // Ora di fine in minuti

    // Finché l'ora corrente non supera l'ora di fine
    while (currentTime <= endTime) {
        const hours = Math.floor(currentTime / 60);
        const minutes = currentTime % 60;

        // Formatto l'ora e i minuti
        const formattedHour = hours.toString().padStart(2, '0');
        const formattedMinute = minutes.toString().padStart(2, '0');

        timeSlots.push(`${formattedHour}:${formattedMinute}`);

        // Aggiungo l'incremento in minuti
        currentTime += increment;
    }

    return timeSlots;
};

  rimuoviSottoServizio(indice: number) {
    this.sottoServiziAggiunti.splice(indice, 1);
  };

  aggiungiSottoServizio() {
    console.log(this.sottoServiziAggiunti);
    this.sottoServiziAggiunti.push({
      name: '',
      description: '',
      cost: 0,
      serviceType: "INSTRUMENTAL_CHECKUP"
    });

  };


}
