import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Order } from "../models/Order";
import { Spedizione } from "../models/Spedizione";
import { apiUrl } from "../../environments/environment";
import { TagContentType } from "@angular/compiler";
import { PdfDownload } from "../models/PdfDownload";
import { UserService } from "../services/user.service";

export interface IProcutList {
  orderId: number;
  total: number;
  DeliveryDate: Date;
  startDelivery: string;
  cartUserId?: number;
  endDelivery: string;
  products: {
    price: number;
    productId: number;
    available: boolean;
    count?: number;
    productNote?: string;
  }[];
  userId?: any;
}

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private orderUrl = `${apiUrl}/order`;
  private orderUrl2 = `${apiUrl}/orderbyemployee`; // url per ottenere la lista degli ordini degli utenti legati all'employee
  private changeUrl = `${apiUrl}/order/changeprices`;
  private changeUrlReward = `${apiUrl}/rewardorder/order/changeprices`;

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Authoritation: "Bearer-token" + this.userS.currentUserValue.token,
    }),
  };

  constructor(private http: HttpClient, private userS: UserService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authoritation: "Bearer-token" + this.userS.currentUserValue.token,
      }),
    };
  }

  getPage(
    page = 1,
    size = 15,
    filtro: string,
    direzione: string
  ): Observable<any> {
    return this.http
      .get(
        `${this.orderUrl}?page=${page}&size=${size}&filter=${filtro}&direction=${direzione}`
      )
      .pipe();
  }

  show(id): Observable<Order> {
    return this.http.get<Order>(`${this.orderUrl}/${id}`).pipe(
      // ordini dalla farmacia preferita

      catchError((_) => of(null))
    );
  }

  showReward(id): Observable<Order> {
    return this.http.get<Order>(`${apiUrl}/rewardorder/order/${id}`).pipe(
      // ordini dalla farmacia preferita

      catchError((_) => of(null))
    );
  }

  //funzione che ritorna la chiamata GLS
  /*   getDelivery(): Observable<Spedizione> {
    return this.http.post<Spedizione>(`${apiUrl}/delivery/new`).pipe();
  } */
  getPage1(page = 1, size = 15): Observable<any> {
    return this.http.get(`${this.orderUrl2}?page=${page}&size=${size}`).pipe();
  }

  getLastSevenMonthsOrders(idShop): Observable<any>{
    return this.http.get<any>(`${apiUrl}/getDeliveryInfo/${idShop}`).pipe();
  }

  getPageEmployee(
    page: number,
    size = 15,
    filtro: string,
    direzione: string,
    status?: any,
    updateTime?: string,
    searchTerm?: string
  ): Observable<any> {
    let s = status ? "&status=" + status : "";
    let u = updateTime ? "&updateTime=" + updateTime : "";
    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    return this.http
      .get(
        `${this.orderUrl2}?page=${page}&size=${size}&filter=${filtro}&direction=${direzione}${s}${u}${sT}`
      )
      .pipe();
  }

  getPageRewardEmployee(
    page: number,
    size = 15,
    filtro: string,
    direzione: string,
    status?: any,
    updateTime?: string,
    updateTimeEnd?: string,
    searchTerm?: string,
    flag = false
  ): Observable<any> {
    let s = status ? "&status=" + status : "";
    let u = updateTime ? "&dateSearchTermStart=" + updateTime : "";
    let uE = updateTimeEnd ? "&dateSearchTermEnd=" + updateTimeEnd : "";
    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    return this.http
      .get(
        `${apiUrl}/rewardorder/orderbyemployee/${flag}?page=${page}&size=${size}&filter=${filtro}&direction=${direzione}${s}${u}${uE}${sT}`
      )
      .pipe();
  }

  getPdfEmployee(status, updateTime, searchTerm): Observable<Blob> {
    let s = status ? "status=" + status : "";
    let u = updateTime ? "&updateTime=" + updateTime : "";
    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    u = s == "" ? "updateTime=" + updateTime : u;
    sT = u == "" ? "searchTerm=" + searchTerm : sT;
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`, {
      responseType: "blob",
    });
  }
  getPdfEmployeeFromTo(from, to): Observable<Blob> {
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http.get(
      `${apiUrl}/pdforderbyemployee?createTime=${from}&updateTime=${to}`,
      {
        responseType: "blob",
      }
    );
  }

  getAllServices(
    page: number,
    size = 15,
    filtro: string,
    direzione: string,
    serviceType: number,
    status?: any,
    updateTime?: string,
    searchTerm?: string
  ): Observable<any> {
    let s = status ? "&status=" + status : "";
    let u = updateTime ? "&updateTime=" + updateTime : "";
    let sT = searchTerm ? "&searchTerm=" + searchTerm : "";
    return this.http
      .get(
        `serviceInShop/getAll/${serviceType}?page=${page}&size=${size}&filter=${filtro}&direction=${direzione}${s}${u}${sT}`
      )
      .pipe();
  }

  getPdfReward(idShop): Observable<Blob> {
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http.get(`${apiUrl}/rewardorder/getordersinfo/pdf/${idShop}`, {
      responseType: "blob",
    });
  }

  getPdfOrderReward(orderId): Observable<Blob> {
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http.get(`${apiUrl}/rewardorder/pdforder/${orderId}`, {
      responseType: "blob",
    });
  }

  getOrdersReward(idShop): Observable<Blob> {
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http
      .get<any>(`${apiUrl}/rewardorder/getordersinfo/${idShop}`)
      .pipe();
  }

  getOrderPdf(idOrdine): Observable<Blob> {
    // return this.http.get(`${apiUrl}/pdforderbyemployee?${s}${u}${sT}`).pipe();
    return this.http.get(`${apiUrl}/pdforder/${idOrdine}`, {
      responseType: "blob",
    });
  }

  getOrdersByInfo(id): Observable<Order[]> {
    return this.http.get<Order[]>(`${apiUrl}/getordersinfo/${id}`);
  }

  sendNotifyForOrder(id): Observable<any> {
    return this.http.get<any>(`${apiUrl}/orderReminder/${id}`);
  }
  

  /* getOrderPdfByFarm2(id): Observable<PdfDownload> {
     return this.http.get<PdfDownload>(`${apiUrl}/getordersinfobase64/pdf/${id}`);
   }*/

  getOrderCsvByFarm2(id): Observable<any> {
    return this.http.get<any>(`${apiUrl}/getordersinfobase64/csv/${id}`);
  }

  getOrderCsvByFarm3(id): Promise<any> {
    var requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.userS.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/getorderscsvbase64?idShop=${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
  }
  getOrderCsv(): Promise<any> {
    var requestOptions = {
      method: "GET",
      headers: new Headers({
        Authorization: "Bearer " + this.userS.currentUserValue.token,
      }),
    };

    return fetch(`${apiUrl}/getorderscsvbase64`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        return result;
      })
      .catch((error) => console.log("error", error));
    /*    const url = `${apiUrl}/getreportgiftcardforcsv`;

    let data = fetch(url, {
      headers: { "Content-Type": "multipart/form-data" },
      method: "POST",
      body: csv,
    });

    console.log(data);
    return data; */
    /*  return this.httpClient.post<any>(url, csv, {
      headers: new HttpHeaders({
        "Content-Type": "text/csv",
      }),
    });*/
  }

  getOrderRewardCsvByFarm2(id): Observable<any> {
    return this.http.get<any>(
      `${apiUrl}/rewardorder/getordersinfobase64/csv/${id}`
    );
  }

  cancel(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/cancel/${id}`, null)
      .pipe(catchError((_) => of(null)));
  }

  finish(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/1`, null)
      .pipe(catchError((_) => of(null)));
  }

  /* allegatoRicette(id): Observable<Order> {
  return this.http
      .patch<any>(`${this.orderUrl}/addBill/${id}`, null)
      .pipe(catchError(_ => of(null)));
 
  } */

  /* getBillImage(id: string, desc: string): Observable<Array<any>>{
var _albums: Array<any> = [];

var i: number;
let descrizione = parseInt(desc, 10);
for(i=1; i<=descrizione; i++ ){
     this.http.get<string[]>(`${apiUrl}/getBillImage/${id}/${i}`,{observe: 'response'})
    _albums.push( {
      src: `${apiUrl}/getBillImage/${id}/${i}`,
      desc: "allegato_"+i+".jpg",
    } );
}
return  of(_albums);
  }
*/
  sendNotes(orderid, note): Observable<any> {
    var body = JSON.stringify({
      note: note,
    });
    return this.http
      .post<any>(`${apiUrl}/addnote/${orderid}`, body)
      .pipe(catchError((_) => of(null)));
  }

  //old
  statusChangerReward(id, status): Observable<Order> {
    //9
    return this.http
      .patch<Order>(
        `${apiUrl}/rewardorder/finish/${id}/${status}`,
        this.httpOptions
      )
      .pipe(catchError((_) => of(null)));
  }

  rewardCollected(id, status, rewardCode): Observable<Order> {
    //9
    return this.http
      .patch<Order>(
        `${apiUrl}/rewardorder/change/${id}/${status}?rewardCode=${rewardCode}`,
        this.httpOptions
      )
      .pipe(catchError((_) => of(null)));
  }


  uploadOrderExcel(excel: any): Promise<any> {
    
    var formdata = new FormData();
    formdata.append("rewardOrdersFile", excel)    
 
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: new Headers({
        Authorization: "Bearer " + this.userS.currentUserValue.token,
      }),
    };
    return fetch(`${apiUrl}/rewardorder/uploadRewardsFromXlsx`, requestOptions)
    .then(response => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.text().then(body => ({
        status: response.status,
        body: body
      }));
    })
    .catch(error => {
      console.error("Error:", error);
      throw error;
    });
  }

  // funzione di order-detail
  sendPreventivo(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/4`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }
  sendPreventivoToSeven(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/7`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }
  statusChanger(id, status): Observable<Order> {
    //9
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/${status}`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }

  statusChangerRewardNew(id, status): Observable<Order> {

    return this.http
      .patch<Order>(`${apiUrl}/rewardorder/change/${id}/${status}`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }
  sendPreventivoToEight(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/8`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }

  nullPreventivo(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/2`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }
  /* 
  getPdf(id): Observable<any>{
  return this.http.get(`${apiUrl}/delivery/pdf/${id}`);
  }
 */
  setPaymentState(idOrdine, totale, farmacia?): Observable<any> {
    let farma = farmacia ? `?shopId=${farmacia}` : "";
    return this.http.post<any>(`${apiUrl}/payment/setpayment/${idOrdine}/2${farma}`, {
      method: "CA",
      paymentId: "Contrassegno",
      total: totale,
      orderId: idOrdine,
    });
  }

  googleApiAddress(via, idShop): Observable<string[]> {
    let url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${via}&key=AIzaSyCD_d536nN7ZHoSXTB5fjE3uGO3LVjqWkM&sessiontoken=${idShop}&language=it&components=country:it`;

    return this.http.get<string[]>(url);
  }
  changePrice(body: IProcutList): Observable<Order> {
    return this.http
      .post<IProcutList>(`${this.changeUrl}`, body, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }

  changePriceReward(body: IProcutList): Observable<Order> {
    return this.http
      .post<IProcutList>(`${this.changeUrlReward}`, body, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }

  orderRetired(id): Observable<Order> {
    return this.http
      .patch<Order>(`${this.orderUrl}/finish/${id}/5`, this.httpOptions)
      .pipe(catchError((_) => of(null)));
  }
}
