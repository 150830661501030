// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: "AIzaSyAmw_Clgi61QSyB8NGU_OpdJo-0ikA0kL8",
    authDomain: "eufarma-26a50.firebaseapp.com",
    databaseURL: "https://eufarma-26a50.firebaseio.com",
    projectId: "eufarma-26a50",
    storageBucket: "eufarma-26a50.appspot.com",
    messagingSenderId: "435871183854",
    appId: "1:435871183854:web:1c822cdc592f93e9ddd4ff",
    measurementId: "G-1S3G0G7NRZ",
  },
};
//official eufarma
//export const apiUrl = "https://app.eufarma.eu:443/api";
//export const apiUrl = 'http://10.212.135.126:8080/api';

//locale
//export const apiUrl = "http://localhost:8081/api";
//macchina Picazio old
//export const apiUrl = "//192.168.108.67:8080/api";

//macchina Picazio old
//export const apiUrl = "//10.212.135.119:8080/api";

////sviluppo
//export const apiUrl = "http://192.168.108.54:8080/api";

//macchina Picazio locale
//export const apiUrl = "http://192.168.108.53:8080/api";

//macchina (test.eufarma|.24)
//export const apiUrl = "https://test.eufarma.eu:443/api";

//const apiUrl = "http://picazio.homelinux.com:8888/api";

//macchina COLLAUDO
export const apiUrl = "https://testeufarma.netcomgroup.eu:8443/api";

//preprod
//export const apiUrl = "https://212.237.40.24:443/api";

//FRONT END E BACKEND
//export const apiUrl = "http://212.237.40.80:8443/api";

//FE: 51.124.152.160
//export const apiUrl = "http://51.124.152.160:8443/api";
//altra macchina;

//macchina Mocerino
//export const apiUrl = "//172.24.174.6:8080/api";

//server Chianese
//export const apiUrl = "//192.168.35.133:8080/api";

//coltanionio new
//export const apiUrl = "//172.24.175.2:8080/api";

//new collaudo
//export const apiUrl = "https://172.24.175.2:8443/api";

//colantonio old
//export const apiUrl = "https://colantonio.homelinux.net:8443/api";

// server preprod
//export const apiUrl = "https://dashboard.netcomgroup.eu:8443/api";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
