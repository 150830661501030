import { Component, Inject, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { User } from "../../models/User";
import { Router, ActivatedRoute } from "@angular/router";
import { Role } from "../../enum/Role";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import timeGrigPlugin from "@fullcalendar/daygrid";
import { Location, formatDate } from "@angular/common";
import interactionPlugin from "@fullcalendar/interaction"; // for dateClick
import { IEventCalendar } from "../calendario/calendario.component";

import { CalendarioService } from "src/app/services/calendario.service";
import { FarmacieService } from "src/app/services/farmacie.service";
import { parseDate } from "@progress/kendo-angular-intl";
import { Farmacia } from "src/app/models/Farmacia";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NotificationServiceService } from "src/app/services/notification.service.service";
export interface DialogTelegram {
  /*   id
  username
  idTelegram
  User: User[] */
  users: [];
  idShop;
}

export interface DialogData {
  Chiusura;
  Notturno;
  startTurnoNotturno;
  endTurnoNotturno;
  Diurno;
  startTurnoDiurno;
  endTurnoDiurno;
  startChiusura;
  endChiusura;
}
export class Week {
  lunedi: string;
  martedi: string;
  mercoledi: string;
  giovedi: string;
  venerdi: string;
  sabato: string;
  domenica: string;
  lunedi2: string;
  martedi2: string;
  mercoledi2: string;
  giovedi2: string;
  venerdi2: string;
  sabato2: string;
  domenica2: string;
}
export class Eccezioni {
  date: Date;
  id?: number;
  idShop?: number;
  openingDay: string;
}
export class Giorno {
  lun: string;
  mar: string;
  mer: string;
  gio: string;
  ven: string;
  sab: string;
  dom: string;
  exc?: Array<Eccezioni>;
  id?: string;
}
@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.css"],
})
export class UserDetailComponent implements OnInit {
  days = new Giorno();
  startDays = new Week();
  endDays = new Week();
  nome: string;
  user = new User();
  name$;
  name: string;
  passwordConfirm: string;
  valueUtente: boolean;
  email: string;
  card: string;
  pageLength: number;
  pageSize = 10;
  pageSizeOptions = [10, 20, 30, 40, 50];
  lock: boolean = true;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = true;
  listaEventiCalendar: IEventCalendar[] = [];
  listaEventi: IEventCalendar[] = [];
  minDate: any;
  role: any;
  giornoSelezionato: any;
  startExc: any;
  endExc: any;
  startExc2: any;
  endExc2: any;
  allDay: boolean;
  fasceOrario: any;
  nameDay: any;
  listChiusure = [
    { type: 'Intervallo date' }, { type: 'Singola data' }];
  elencoChiusura: string = '';
  daysFrom: string = "";
  daysTo: string = "";
  singleDay: string = "";

  fasceOrariGiorni = [
    { time: "Unico turno/Continuato" }, //Orario Continuato
    { time: "A turni" }, // a turni
  ];
  listaEccezioni: Array<Eccezioni> = [];
  listaFerie: Array<Eccezioni> = [];
  arrayFerie: Array<Eccezioni> = [];
  arrayFerie2: Array<Eccezioni> = [];
  listaChisure: Array<Eccezioni> = [];
  fasceOrarioLunedi: string;
  fasceOrarioMartedi: string;
  fasceOrarioMercoledi: string;
  fasceOrarioGiovedi: string;
  fasceOrarioVenerdi: string;
  fasceOrarioSabato: string;
  fasceOrarioDomenica: string;
  Diurno: boolean;
  Chiusura: boolean;
  Notturno: boolean;
  shop: number;
  nomeShop: string;
  startChiusura: string = "00:00";
  endChiusura: string = "23:59";
  startTurnoNotturno: string;
  endTurnoNotturno: string;
  startTurnoDiurno: string;
  endTurnoDiurno: string;
  time: string;
  offset: boolean;
  disabledButton = false;
  farmacia: Farmacia = new Farmacia();
  citta;
  telegramCode: string = "";
  prov;
  titoloSezione: string = "Profilo utente";
  constructor(
    private farmaciaService: FarmacieService,
    private userService: UserService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,

    private calendarService: CalendarioService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
  ) {
    this.minDate = formatDate(Date.now(), "yyyy-MM-dd", "it-IT");
  }

  ngOnInit() {
    this.fasceOrarioLunedi =
      this.startDays.lunedi2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioMartedi =
      this.startDays.martedi2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioMercoledi =
      this.startDays.mercoledi2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioGiovedi =
      this.startDays.giovedi2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioVenerdi =
      this.startDays.venerdi2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioSabato =
      this.startDays.sabato2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.fasceOrarioDomenica =
      this.startDays.domenica2 == undefined
        ? "A turni"
        : "Unico turno/Continuato";
    this.role = this.userService.currentUserValue.role;
    const idUtente = this.route.snapshot.paramMap.get("id");
    if (!this.router.url.includes("/profiloutente")) {
      this.spinner.show();
      this.valueUtente = false;
      this.titoloSezione = "Profilo Farmacia";
      this.userService
        .get(this.userService.currentUserValue.account)
        .subscribe((x) => {
          this.nome = x.name;
          this.shop = x.shop;
          this.farmaciaService.getFarmacia(this.shop).subscribe((farma) => {
            this.farmacia = farma;
            /*         this.farmacia = farma; */
            this.farmacia.deliveryService =
              this.farmacia.deliveryService == null
                ? "Nessun corriere"
                : this.farmacia.deliveryService;
            this.citta = farma.idCity.split(/[ ]|[(]|[)]/g)[0];
            this.prov = farma.idCity.split(/[ ]|[(]|[)]/g)[2];
            farma.idCity.split(/[ ]|[(]|[)]/g)[2]
              ? (this.prov = farma.idCity.split(/[ ]|[(]|[)]/g)[2])
              : (this.prov = " ");
            farma.vat
              ? (this.farmacia.vat = farma.vat)
              : (this.farmacia.vat = " ");
            this.farmacia.address =
              farma.address +
              ", " +
              farma.vat +
              " " +
              farma.idCity.replace(/[(]|[)]/g, "") +
              ", " +
              "Italia";
            this.nomeShop = farma.name;
            this.farmacia.webUrl = farma.webUrl;
            /*             console.log(this.nomeShop); */
          });

        });
      const account = this.userService.currentUserValue.account;
      this.userService.get(account).subscribe(
        (u) => {
          this.user.id = u.id;
          this.user.name = u.name;
          this.user.email = u.email;
          this.user.address = u.address;
          this.user.shop = u.shop;
          this.user.phone = u.phone;
          this.user.idshop = u.idshop;
          this.user.password = u.password;
          this.user.telegramCode = u.telegramCode
            ? u.id + "-" + u.telegramCode
            : "";
          this.getShopCalendar();
          this.spinner.hide();
        },
        (e) => { }
      );
    } else if (this.router.url.includes("/profiloutente")) {
      this.valueUtente = true;
      this.userService.getprofilebyId(+idUtente).subscribe(
        (u) => {
          let splittedBorn = u.born.split("T")[0];
          this.user.id = u.id;
          this.user.email = u.email;
          this.user.address = u.address;
          this.user.shop = u.shop;
          this.user.name = u.name;
          this.user.phone = u.phone;
          this.user.idshop = u.idshop;
          this.user.sex = u.sex;
          this.user.idCity = u.idCity;
          this.user.vat = u.vat;
          this.user.surname = u.surname;
          this.user.born = splittedBorn;
          this.user.idGiftCard = u.idGiftCard;
          this.userService.giftcardbyid(u.idGiftCard).subscribe((x) => {
            this.card = x.number;
          });
          this.user.fiscalCode = u.fiscalCode;
        },
        (e) => { }
      );
    }
  }
  handleEventClick(arg) {
    if (arg.dateStr >= this.minDate) {
      this.startTurnoNotturno = "--:--";
      this.startTurnoDiurno = "--:--";
      this.endTurnoDiurno = "--:--";
      this.endTurnoNotturno = "--:--";
      const dialogRef = this.dialog.open(DialogUser, {
        width: "480px",
        data: {
          dataScelta: arg.dateStr,
          Chiusura: this.Chiusura,
          Notturno: this.Notturno,
          startTurnoNotturno: this.startTurnoNotturno,
          endTurnoNotturno: this.endTurnoNotturno,
          Diurno: this.Diurno,
          startTurnoDiurno: this.startTurnoDiurno,
          endTurnoDiurno: this.endTurnoDiurno,
          startChiusura: this.startChiusura,
          endChiusura: this.endChiusura,
        },
      });

      dialogRef.beforeClosed().subscribe((result) => {
        if (result != undefined) {
          this.Chiusura = result.Chiusura;
          this.Notturno = result.Notturno;
          this.startTurnoNotturno = result.startTurnoNotturno;
          this.endTurnoNotturno = result.endTurnoNotturno;
          this.Diurno = result.Diurno;
          this.startTurnoDiurno = result.startTurnoDiurno;
          this.endTurnoDiurno = result.endTurnoDiurno;
          this.startChiusura = result.startChiusura;
          this.endChiusura = result.endChiusura;
          this.YourConfirmEvent();
        }
      });
      /*   this.popup.options = {
        header: "Turni e Chiusure",
        color: "rgb(251,185,0)", // red, blue....
        widthProsentage: 30, // The with of the popou measured by browser width
        animationDuration: 1, // in seconds, 0 = no animation
        showButtons: true, // You can hide this in case you want to use custom buttons
        confirmBtnContent: "Invia", // The text on your confirm button
        cancleBtnContent: "Annulla", // the text on your cancel button
        confirmBtnClass: "btn btn-default", // your class for styling the confirm button
        cancleBtnClass: "btn btn-default", // you class for styling the cancel button

        animation: "fadeInDown", // 'fadeInLeft', 'fadeInRight', 'fadeInUp', 'bounceIn','bounceInDown'
      }; */
      this.giornoSelezionato = arg;
      /*  this.popup.show(this.popup.options); */
    }
  }

  change(valore) {
    if (valore == false) {
      this.startChiusura = "00:00";
      this.endChiusura = "23:59";
    } else if (valore == true) {
      this.startChiusura = "--:--";
      this.endChiusura = "--:--";
    }
  }

  YourConfirmEvent() {
    if (this.Notturno == true && this.Diurno == true) {
      var numberDay1 = new Date(this.giornoSelezionato.dateStr).getDay();
      var openDay;
      switch (numberDay1) {
        case 0:
          openDay =
            this.days.dom +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 1:
          openDay =
            this.days.lun +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 2:
          openDay =
            this.days.mar +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 3:
          openDay =
            this.days.mer +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 4:
          openDay =
            this.days.gio +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 5:
          openDay =
            this.days.ven +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
        case 6:
          openDay =
            this.days.sab +
            "(" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN)" +
            " " +
            "(" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD)";
          break;
      }
      const eccezione = {
        date: new Date(this.giornoSelezionato.dateStr),
        openingDay: openDay.replace("chiuso-chiuso chiuso-chiuso", ""),
      };
      this.listaEccezioni.push(eccezione);

      //}
    } else if (this.Notturno == true && this.Diurno == false) {
      var numberDay2 = new Date(this.giornoSelezionato.dateStr).getDay();
      var openDay2;
      switch (numberDay2) {
        case 0:
          openDay2 =
            this.days.dom +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 1:
          openDay2 =
            this.days.lun +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 2:
          openDay2 =
            this.days.mar +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 3:
          openDay2 =
            this.days.mer +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 4:
          openDay2 =
            this.days.gio +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 5:
          openDay2 =
            this.days.ven +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
        case 6:
          openDay2 =
            this.days.sab +
            " (" +
            this.startTurnoNotturno +
            "-" +
            this.endTurnoNotturno +
            " TN" +
            ")";
          break;
      }

      const eccezione = {
        date: new Date(this.giornoSelezionato.dateStr),
        openingDay: openDay2.replace("chiuso-chiuso chiuso-chiuso", ""),
      };
      this.listaEccezioni.push(eccezione);

      //}
    } else if (this.Notturno == false && this.Diurno == true) {
      var numberDay3 = new Date(this.giornoSelezionato.dateStr).getDay();
      var openDay3;
      switch (numberDay3) {
        case 0:
          openDay3 =
            " " +
            this.days.dom +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 1:
          openDay3 =
            " " +
            this.days.lun +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 2:
          openDay3 =
            " " +
            this.days.mar +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 3:
          openDay3 =
            " " +
            this.days.mer +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 4:
          openDay3 =
            " " +
            this.days.gio +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 5:
          openDay3 =
            " " +
            this.days.ven +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
        case 6:
          openDay3 =
            " " +
            this.days.sab +
            " (" +
            this.startTurnoDiurno +
            "-" +
            this.endTurnoDiurno +
            " TD" +
            ")";
          break;
      }

      const eccezione = {
        date: new Date(this.giornoSelezionato.dateStr),
        openingDay: openDay3.replace("chiuso-chiuso chiuso-chiuso", ""),
      };
      this.listaEccezioni.push(eccezione);

      // }
    } else {
      alert("Attenzione!, Scegli una fascia orario oppure clicca su Annulla.");
    }
    /* const eccezione = {
        date: new Date(this.giornoSelezionato.dateStr),
        openingDay: "Chiuso-Chiuso",
      };
      this.listaEccezioni.push(eccezione);
      this.popup.hide();*/
    /* } else {
       this.startExc = this.startExc == undefined ? "" : this.startExc;
       this.endExc = this.endExc == undefined ? "" : this.endExc;
       this.startExc2 = this.startExc2 == undefined ? "" : this.startExc2;
       this.endExc2 = this.endExc2 == undefined ? "" : this.endExc2;
       if (
         parseInt(this.startExc) - parseInt(this.endExc) < 0 ||
         parseInt(this.startExc2) - parseInt(this.endExc2)
       ) {
         const eccezione = {
           date: new Date(this.giornoSelezionato.dateStr),
           openingDay:
             this.startExc +
             "-" +
             this.endExc +
             "-" +
             this.startExc2 +
             "-" +
             this.endExc2,
         };
         this.listaEccezioni.push(eccezione);
         this.popup.hide();
       } /*else {
         alert(
           "Attenzione, l'orario di apertura non può essere inferiore a quello di chiusura."
         );
       }*/
  }

  onSubmit() {
    if (this.router.url.includes("/profiloutente")) {
      // if (this.user.password == this.passwordConfirm) {

      this.userService.update(this.user).subscribe((u) => {
        this.userService.nameTerms.next(u.name);
      });
      if (this.userService.currentUserValue.role == Role.Employee) {
        this.router.navigate(["/orderbyemployee"]);
      } else if (this.userService.currentUserValue.role == Role.Manager) {
        this.router.navigate(["/farmacie"]);
      }
      // }
    } else {
      this.userService.update(this.user).subscribe((u) => {
        this.userService.nameTerms.next(u.name);
      });
      if (this.userService.currentUserValue.role == Role.Employee) {
        this.router.navigate(["/orderbyemployee"]);
      } else if (this.userService.currentUserValue.role == Role.Manager) {
        this.router.navigate(["/farmacie"]);
      }
    }
  }
  cancellaUtente() {
    var r = confirm("Attenzione, sei sicuro di voler cancellare l'utente?");
    if (r == true) {
      this.userService
        .deleteUsers(+this.route.snapshot.paramMap.get("id"))
        .subscribe((x) => {
          this.router.navigate(["/lista-utenti"]);
        });
    }
  }
  lockUtente() {
    this.lock = !this.lock;
  }
  showTelegramList() {
    const dialogRef = this.dialog.open(DialogUserTelegram, {
      width: "700px",
      data: {
        idShop: this.shop,
      },
    });
  }

  setTypeChiusura(event) {
    this.elencoChiusura = event.target.value;
    if (this.elencoChiusura == 'Singola data') {
      this.daysFrom = "";
      this.daysTo = "";
    } else {
      this.singleDay = "";
    }
  };

  aggiornaOrario() {

    if (this.elencoChiusura == 'Singola data') {
      if (this.singleDay != "") {
        if (!this.validaOrari(this.startChiusura, this.endChiusura)) {
          alert("Attenzione! Controllare gli orari inseriti nella chiusura");
          return;
        } else {
          let value = this.listaChisure.find(elem => new Date(elem.date).getDate() === new Date(this.singleDay).getDate() && new Date(elem.date).getMonth()+1 === new Date(this.singleDay).getMonth()+1);
          if (!value) {
            const eccezione = {
              date: new Date(this.singleDay),
              openingDay: this.startChiusura + "-" + this.endChiusura + " CH",
            };
            this.listaEccezioni.push(eccezione);
          } else {
            alert("Attenzione! La data selezionata è già impostata per una chiusura");
            return;
          }
        }
      } else {
        alert("Attenzione! Selezionare una data per la chiusura");
        return;
      }
    } else if (this.elencoChiusura == 'Intervallo date') {
      if (!this.validaOrari(this.startChiusura, this.endChiusura)) {
        alert("Attenzione! Controllare gli orari inseriti nella chiusura");
        return;
      } else if (this.daysFrom == "" || this.daysTo == "") {
        alert("Attenzione! Controllare data di inizio o fine chisura mancante");
        return;
      } else {

        const days = [];

        // Assicurati che start sia prima di end
        if (this.daysFrom > this.daysTo) {
          alert("La data di inizio deve essere precedente alla data di fine.");
          return;
        }

        // Aggiungi ogni giorno all'array
        for (let d = new Date(this.daysFrom); d <= new Date(this.daysTo); d.setDate(d.getDate() + 1)) {
          let value = this.listaChisure.some(elem => new Date(elem.date).getDate() === d.getDate() && new Date(elem.date).getMonth()+1 === d.getMonth()+1);
          if (!value) {
            this.listaEccezioni.push({
              date: new Date(d),
              openingDay: this.startChiusura + "-" + this.endChiusura + " CH",
            }); // Formatta la data in YYYY-MM-DD
          }
        }

      }
    }

    let lun = this.startDays.lunedi
      ? parseInt(this.startDays.lunedi) - parseInt(this.endDays.lunedi)
      : -1;
    if (parseInt(this.startDays.lunedi) - parseInt(this.endDays.lunedi) == -1) {
      lun = -2;
    }
    let mar = this.startDays.martedi
      ? parseInt(this.startDays.martedi) - parseInt(this.endDays.martedi)
      : -1;
    if (
      parseInt(this.startDays.martedi) - parseInt(this.endDays.martedi) ==
      -1
    ) {
      mar = -2;
    }
    let mer = this.startDays.mercoledi
      ? parseInt(this.startDays.mercoledi) - parseInt(this.endDays.mercoledi)
      : -1;
    if (
      parseInt(this.startDays.mercoledi) - parseInt(this.endDays.mercoledi) ==
      -1
    ) {
      mer = -2;
    }
    let gio = this.startDays.giovedi
      ? parseInt(this.startDays.giovedi) - parseInt(this.endDays.giovedi)
      : -1;
    if (
      parseInt(this.startDays.giovedi) - parseInt(this.endDays.giovedi) ==
      -1
    ) {
      gio = -2;
    }
    let ven = this.startDays.venerdi
      ? parseInt(this.startDays.venerdi) - parseInt(this.endDays.venerdi)
      : -1;
    if (
      parseInt(this.startDays.venerdi) - parseInt(this.endDays.venerdi) ==
      -1
    ) {
      ven = -2;
    }
    let sab = this.startDays.sabato
      ? parseInt(this.startDays.sabato) - parseInt(this.endDays.sabato)
      : -1;
    if (parseInt(this.startDays.sabato) - parseInt(this.endDays.sabato) == -1) {
      sab = -2;
    }
    let dom = this.startDays.domenica
      ? parseInt(this.startDays.domenica) - parseInt(this.endDays.domenica)
      : -1;
    if (
      parseInt(this.startDays.domenica) - parseInt(this.endDays.domenica) ==
      -1
    ) {
      dom = -2;
    }

    let lun2 = this.startDays.lunedi2
      ? parseInt(this.startDays.lunedi2) - parseInt(this.endDays.lunedi2)
      : -1;
    let mar2 = this.startDays.martedi2
      ? parseInt(this.startDays.martedi2) - parseInt(this.endDays.martedi2)
      : -1;
    let mer2 = this.startDays.mercoledi2
      ? parseInt(this.startDays.mercoledi2) - parseInt(this.endDays.mercoledi2)
      : -1;
    let gio2 = this.startDays.giovedi2
      ? parseInt(this.startDays.giovedi2) - parseInt(this.endDays.giovedi2)
      : -1;
    let ven2 = this.startDays.venerdi2
      ? parseInt(this.startDays.venerdi2) - parseInt(this.endDays.venerdi2)
      : -1;
    let sab2 = this.startDays.sabato2
      ? parseInt(this.startDays.sabato2) - parseInt(this.endDays.sabato2)
      : -1;
    let dom2 = this.startDays.domenica2
      ? parseInt(this.startDays.domenica2) - parseInt(this.endDays.domenica2)
      : -1;

    if (this.fasceOrarioLunedi == "Unico turno/Continuato") {
      if (this.nameDay == "Lunedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.lun = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCL1 = new Date("01/01/2200 " + this.startChiusura);
          var endCL1 = new Date("01/01/2200 " + this.endChiusura);
          var time1L1 = new Date("01/01/2200 " + this.startDays.lunedi);
          var time2L1 = new Date("01/01/2200 " + this.endDays.lunedi);
          if (startCL1 < time1L1) {
            if (endCL1 < time1L1) {
              this.days.lun = (
                this.startDays.lunedi +
                "-" +
                this.endDays.lunedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCL1 >= time1L1) {
              if (endCL1 < time2L1) {
                this.days.lun = (
                  this.endChiusura +
                  "-" +
                  this.endDays.lunedi +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCL1 >= time2L1) {
                this.days.lun = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCL1 > time1L1) {
            if (startCL1 < time2L1) {
              if (endCL1 >= time2L1) {
                this.days.lun = (
                  this.startDays.lunedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCL1 < time2L1) {
                this.fasceOrarioLunedi = "A turni";
                this.days.lun = (
                  this.startDays.lunedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.lunedi
                ).toString();
              }
            } else if (startCL1 >= time2L1) {
              this.days.lun = (
                this.startDays.lunedi +
                "-" +
                this.endDays.lunedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.lunedi) {
            if (this.endChiusura == this.endDays.lunedi) {
              this.days.lun = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCL1 > time2L1) {
              this.days.lun = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCL1 < time2L1) {
              this.days.lun = (
                this.endChiusura +
                "-" +
                this.endDays.lunedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
          /*this.days.lun = (
            this.startDays.lunedi +
            "-" +
            this.endDays.lunedi +
            " " +
            "(chiuso " +
            " dalle " +
            this.startChiusura +
            " alle " +
            this.endChiusura +
            ")"
          ).toString();*/
        }
      } else {
        this.startDays.lunedi = this.startDays.lunedi
          ? this.startDays.lunedi
          : "chiuso";
        this.endDays.lunedi = this.endDays.lunedi
          ? this.endDays.lunedi
          : "chiuso";
        this.days.lun = (
          this.startDays.lunedi +
          "-" +
          this.endDays.lunedi +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioLunedi == "A turni") {
      if (this.nameDay == "Lunedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.lun = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCL = new Date("01/01/2100 " + this.startChiusura);
          var endCL = new Date("01/01/2100 " + this.endChiusura);
          var time1L = new Date("01/01/2100 " + this.startDays.lunedi);
          var time2L = new Date("01/01/2100 " + this.endDays.lunedi);
          var time3L = new Date("01/01/2100 " + this.startDays.lunedi2);
          var time4L = new Date("01/01/2100 " + this.endDays.lunedi2);
          if (startCL < time1L) {
            if (endCL > time2L) {
              this.days.lun = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.lunedi2
              ).toString();
            } else if (endCL < time2L) {
              this.days.lun = (
                this.endChiusura +
                "-" +
                this.endDays.lunedi +
                " " +
                this.startDays.lunedi2 +
                "-" +
                this.endDays.lunedi2
              ).toString();
            } else if (endCL == time2L) {
              this.days.lun = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.lunedi2
              ).toString();
            }
          } else if (startCL > time1L) {
            if (startCL >= time2L) {
              if (endCL < time3L) {
                this.days.lun = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.lunedi2
                ).toString();
              } else if (endCL > time3L) {
                if (endCL > time4L) {
                  this.days.lun = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCL < time4L) {
                  this.days.lun = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.lunedi2
                  ).toString();
                }
              }
            } else if (startCL < time2L) {
              if (endCL < time2L) {
                this.days.lun = (
                  this.endDays.lunedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.lunedi2
                ).toString();
              } else if (endCL > time2L) {
                if (endCL < time3L) {
                  this.days.lun = (
                    this.endDays.lunedi +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.lunedi2
                  ).toString();
                } else if (endCL > time3L) {
                  if (endCL < time4L) {
                    this.days.lun = (
                      this.endDays.lunedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.lunedi2
                    ).toString();
                  } else if (endCL > time4L) {
                    this.days.lun = (
                      this.endDays.lunedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (lun < 0 && lun2 < 0) {
          this.startDays.lunedi = this.startDays.lunedi
            ? this.startDays.lunedi
            : "chiuso";
          this.endDays.lunedi = this.endDays.lunedi
            ? this.endDays.lunedi
            : "chiuso";
          this.startDays.lunedi2 = this.startDays.lunedi2
            ? this.startDays.lunedi2
            : "chiuso";
          this.endDays.lunedi2 = this.endDays.lunedi2
            ? this.endDays.lunedi2
            : "chiuso";
          this.days.lun = (
            this.startDays.lunedi +
            "-" +
            this.endDays.lunedi +
            " " +
            this.startDays.lunedi2 +
            "-" +
            this.endDays.lunedi2
          ).toString();
        }
        /* else {
           alert(
             "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
           );
         }*/
      }
    }
    if (this.fasceOrarioMartedi == "Unico turno/Continuato") {
      if (this.nameDay == "Martedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.mar = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCMA1 = new Date("01/01/2200 " + this.startChiusura);
          var endCMA1 = new Date("01/01/2200 " + this.endChiusura);
          var time1MA1 = new Date("01/01/2200 " + this.startDays.martedi);
          var time2MA1 = new Date("01/01/2200 " + this.endDays.martedi);
          if (startCMA1 < time1MA1) {
            if (endCMA1 < time1MA1) {
              this.days.mar = (
                this.startDays.martedi +
                "-" +
                this.endDays.martedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCMA1 >= time1MA1) {
              if (endCMA1 < time2MA1) {
                this.days.mar = (
                  this.endChiusura +
                  "-" +
                  this.endDays.martedi +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCMA1 >= time2MA1) {
                this.days.mar = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCMA1 > time1MA1) {
            if (startCMA1 < time2MA1) {
              if (endCMA1 >= time2MA1) {
                this.days.mar = (
                  this.startDays.martedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCMA1 < time2MA1) {
                this.fasceOrarioMartedi = "A turni";
                this.days.mar = (
                  this.startDays.martedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.martedi
                ).toString();
              }
            } else if (startCMA1 >= time2MA1) {
              this.days.mar = (
                this.startDays.martedi +
                "-" +
                this.endDays.martedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.martedi) {
            if (this.endChiusura == this.endDays.martedi) {
              this.days.mar = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCMA1 > time2MA1) {
              this.days.mar = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCMA1 < time2MA1) {
              this.days.mar = (
                this.endChiusura +
                "-" +
                this.endDays.martedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.martedi = this.startDays.martedi
          ? this.startDays.martedi
          : "chiuso";
        this.endDays.martedi = this.endDays.martedi
          ? this.endDays.martedi
          : "chiuso";
        this.days.mar = (
          this.startDays.martedi +
          "-" +
          this.endDays.martedi +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioMartedi == "A turni") {
      if (this.nameDay == "Martedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.mar = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCMA = new Date("01/01/2100 " + this.startChiusura);
          var endCMA = new Date("01/01/2100 " + this.endChiusura);
          var time1MA = new Date("01/01/2100 " + this.startDays.martedi);
          var time2MA = new Date("01/01/2100 " + this.endDays.martedi);
          var time3MA = new Date("01/01/2100 " + this.startDays.martedi2);
          var time4MA = new Date("01/01/2100 " + this.endDays.martedi2);
          if (startCMA < time1MA) {
            if (endCMA > time2MA) {
              this.days.mar = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.martedi2
              ).toString();
            } else if (endCMA < time2MA) {
              this.days.mar = (
                this.endChiusura +
                "-" +
                this.endDays.martedi +
                " " +
                this.startDays.martedi2 +
                "-" +
                this.endDays.martedi2
              ).toString();
            } else if (endCMA == time2MA) {
              this.days.mar = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.martedi2
              ).toString();
            }
          } else if (startCMA > time1MA) {
            if (startCMA >= time2MA) {
              if (endCMA < time3MA) {
                this.days.mar = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.martedi2
                ).toString();
              } else if (endCMA > time3MA) {
                if (endCMA > time4MA) {
                  this.days.mar = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCMA < time4MA) {
                  this.days.mar = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.martedi2
                  ).toString();
                }
              }
            } else if (startCMA < time2MA) {
              if (endCMA < time2MA) {
                this.days.mar = (
                  this.endDays.martedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.martedi2
                ).toString();
              } else if (endCMA > time2MA) {
                if (endCMA < time3MA) {
                  this.days.mar = (
                    this.endDays.martedi +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.martedi2
                  ).toString();
                } else if (endCMA > time3MA) {
                  if (endCMA < time4MA) {
                    this.days.mar = (
                      this.endDays.martedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.martedi2
                    ).toString();
                  } else if (endCMA > time4MA) {
                    this.days.mar = (
                      this.endDays.martedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (mar < 0 && mar2 < 0) {
          this.startDays.martedi = this.startDays.martedi
            ? this.startDays.martedi
            : "chiuso";
          this.endDays.martedi = this.endDays.martedi
            ? this.endDays.martedi
            : "chiuso";
          this.startDays.martedi2 = this.startDays.martedi2
            ? this.startDays.martedi2
            : "chiuso";
          this.endDays.martedi2 = this.endDays.martedi2
            ? this.endDays.martedi2
            : "chiuso";
          this.days.mar = (
            this.startDays.martedi +
            "-" +
            this.endDays.martedi +
            " " +
            this.startDays.martedi2 +
            "-" +
            this.endDays.martedi2
          ).toString();
        }
        /*  else if(this.days.mar != "" && mar >= 0  && mar2 >= 0){
            alert(
              "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
            );
          }*/
      }
    }
    if (this.fasceOrarioMercoledi == "Unico turno/Continuato") {
      if (this.nameDay == "Mercoledi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.mer = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCME1 = new Date("01/01/2200 " + this.startChiusura);
          var endCME1 = new Date("01/01/2200 " + this.endChiusura);
          var time1ME1 = new Date("01/01/2200 " + this.startDays.mercoledi);
          var time2ME1 = new Date("01/01/2200 " + this.endDays.mercoledi);
          if (startCME1 < time1ME1) {
            if (endCME1 < time1ME1) {
              this.days.mer = (
                this.startDays.mercoledi +
                "-" +
                this.endDays.mercoledi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCME1 >= time1ME1) {
              if (endCME1 < time2ME1) {
                this.days.mer = (
                  this.endChiusura +
                  "-" +
                  this.endDays.mercoledi +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCME1 >= time2ME1) {
                this.days.mer = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCME1 > time1ME1) {
            if (startCME1 < time2ME1) {
              if (endCME1 >= time2ME1) {
                this.days.mer = (
                  this.startDays.mercoledi +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCME1 < time2ME1) {
                this.fasceOrarioMercoledi = "A turni";
                this.days.mer = (
                  this.startDays.mercoledi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.mercoledi
                ).toString();
              }
            } else if (startCME1 >= time2ME1) {
              this.days.mer = (
                this.startDays.mercoledi +
                "-" +
                this.endDays.mercoledi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.mercoledi) {
            if (this.endChiusura == this.endDays.mercoledi) {
              this.days.mer = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCME1 > time2ME1) {
              this.days.mer = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCME1 < time2ME1) {
              this.days.mer = (
                this.endChiusura +
                "-" +
                this.endDays.mercoledi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.mercoledi = this.startDays.mercoledi
          ? this.startDays.mercoledi
          : "chiuso";
        this.endDays.mercoledi = this.endDays.mercoledi
          ? this.endDays.mercoledi
          : "chiuso";
        this.days.mer = (
          this.startDays.mercoledi +
          "-" +
          this.endDays.mercoledi +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioMercoledi == "A turni") {
      if (this.nameDay == "Mercoledi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.mer = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCME = new Date("01/01/2100 " + this.startChiusura);
          var endCME = new Date("01/01/2100 " + this.endChiusura);
          var time1ME = new Date("01/01/2100 " + this.startDays.mercoledi);
          var time2ME = new Date("01/01/2100 " + this.endDays.mercoledi);
          var time3ME = new Date("01/01/2100 " + this.startDays.mercoledi);
          var time4ME = new Date("01/01/2100 " + this.endDays.mercoledi);
          if (startCME < time1ME) {
            if (endCME > time2ME) {
              this.days.mer = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.mercoledi2
              ).toString();
            } else if (endCME < time2ME) {
              this.days.mer = (
                this.endChiusura +
                "-" +
                this.endDays.mercoledi +
                " " +
                this.startDays.mercoledi2 +
                "-" +
                this.endDays.mercoledi2
              ).toString();
            } else if (endCME == time2ME) {
              this.days.mer = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.mercoledi2
              ).toString();
            }
          } else if (startCME > time1ME) {
            if (startCME >= time2ME) {
              if (endCME < time3ME) {
                this.days.mer = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.mercoledi2
                ).toString();
              } else if (endCME > time3ME) {
                if (endCME > time4ME) {
                  this.days.mer = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCME < time4ME) {
                  this.days.mer = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.mercoledi2
                  ).toString();
                }
              }
            } else if (startCME < time2ME) {
              if (endCME < time2ME) {
                this.days.mer = (
                  this.endDays.mercoledi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.mercoledi2
                ).toString();
              } else if (endCME > time2ME) {
                if (endCME < time3ME) {
                  this.days.mer = (
                    this.endDays.mercoledi +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.mercoledi2
                  ).toString();
                } else if (endCME > time3ME) {
                  if (endCME < time4ME) {
                    this.days.mer = (
                      this.endDays.mercoledi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.mercoledi2
                    ).toString();
                  } else if (endCME > time4ME) {
                    this.days.mer = (
                      this.endDays.mercoledi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (mer < 0 && mer2 < 0) {
          this.startDays.mercoledi = this.startDays.mercoledi
            ? this.startDays.mercoledi
            : "chiuso";
          this.endDays.mercoledi = this.endDays.mercoledi
            ? this.endDays.mercoledi
            : "chiuso";
          this.startDays.mercoledi2 = this.startDays.mercoledi2
            ? this.startDays.mercoledi2
            : "chiuso";
          this.endDays.mercoledi2 = this.endDays.mercoledi2
            ? this.endDays.mercoledi2
            : "chiuso";
          this.days.mer = (
            this.startDays.mercoledi +
            "-" +
            this.endDays.mercoledi +
            " " +
            this.startDays.mercoledi2 +
            "-" +
            this.endDays.mercoledi2
          ).toString();
        }
        /*  else {
            alert(
              "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
            );
          }*/
      }
    }
    if (this.fasceOrarioGiovedi == "Unico turno/Continuato") {
      if (this.nameDay == "Giovedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.gio = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCG1 = new Date("01/01/2200 " + this.startChiusura);
          var endCG1 = new Date("01/01/2200 " + this.endChiusura);
          var time1G1 = new Date("01/01/2200 " + this.startDays.giovedi);
          var time2G1 = new Date("01/01/2200 " + this.endDays.giovedi);
          if (startCG1 < time1G1) {
            if (endCG1 < time1G1) {
              this.days.gio = (
                this.startDays.giovedi +
                "-" +
                this.endDays.giovedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCG1 >= time1G1) {
              if (endCG1 < time2G1) {
                this.days.gio = (
                  this.endChiusura +
                  "-" +
                  this.endDays.giovedi +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCG1 >= time2G1) {
                this.days.gio = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCG1 > time1G1) {
            if (startCG1 < time2G1) {
              if (endCG1 >= time2G1) {
                this.days.gio = (
                  this.startDays.giovedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCG1 < time2G1) {
                this.fasceOrarioGiovedi = "A turni";
                this.days.mer = (
                  this.startDays.giovedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.giovedi
                ).toString();
              }
            } else if (startCG1 >= time2G1) {
              this.days.gio = (
                this.startDays.giovedi +
                "-" +
                this.endDays.giovedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.giovedi) {
            if (this.endChiusura == this.endDays.giovedi) {
              this.days.gio = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCG1 > time2G1) {
              this.days.gio = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCG1 < time2G1) {
              this.days.gio = (
                this.endChiusura +
                "-" +
                this.endDays.giovedi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.giovedi = this.startDays.giovedi
          ? this.startDays.giovedi
          : "chiuso";
        this.endDays.giovedi = this.endDays.giovedi
          ? this.endDays.giovedi
          : "chiuso";
        this.days.gio = (
          this.startDays.giovedi +
          "-" +
          this.endDays.giovedi +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioGiovedi == "A turni") {
      if (this.nameDay == "Giovedi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.gio = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCGO = new Date("01/01/2100 " + this.startChiusura);
          var endCGO = new Date("01/01/2100 " + this.endChiusura);
          var time1GO = new Date("01/01/2100 " + this.startDays.giovedi);
          var time2GO = new Date("01/01/2100 " + this.endDays.giovedi);
          var time3GO = new Date("01/01/2100 " + this.startDays.giovedi);
          var time4GO = new Date("01/01/2100 " + this.endDays.giovedi);
          if (startCGO < time1GO) {
            if (endCGO > time2GO) {
              this.days.gio = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.giovedi2
              ).toString();
            } else if (endCGO < time2GO) {
              this.days.gio = (
                this.endChiusura +
                "-" +
                this.endDays.giovedi +
                " " +
                this.startDays.giovedi2 +
                "-" +
                this.endDays.giovedi2
              ).toString();
            } else if (endCGO == time2GO) {
              this.days.gio = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.giovedi2
              ).toString();
            }
          } else if (startCGO > time1GO) {
            if (startCGO >= time2GO) {
              if (endCGO < time3GO) {
                this.days.gio = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.giovedi2
                ).toString();
              } else if (endCGO > time3GO) {
                if (endCGO > time4GO) {
                  this.days.gio = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCGO < time4GO) {
                  this.days.gio = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.giovedi2
                  ).toString();
                }
              }
            } else if (startCGO < time2GO) {
              if (endCGO < time2GO) {
                this.days.gio = (
                  this.endDays.giovedi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.giovedi2
                ).toString();
              } else if (endCGO > time2GO) {
                if (endCGO < time3GO) {
                  this.days.gio = (
                    this.endDays.giovedi +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.giovedi2
                  ).toString();
                } else if (endCGO > time3GO) {
                  if (endCGO < time4GO) {
                    this.days.gio = (
                      this.endDays.giovedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.giovedi2
                    ).toString();
                  } else if (endCGO > time4GO) {
                    this.days.gio = (
                      this.endDays.giovedi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (gio < 0 && gio2 < 0) {
          this.startDays.giovedi = this.startDays.giovedi
            ? this.startDays.giovedi
            : "chiuso";
          this.endDays.giovedi = this.endDays.giovedi
            ? this.endDays.giovedi
            : "chiuso";
          this.startDays.giovedi2 = this.startDays.giovedi2
            ? this.startDays.giovedi2
            : "chiuso";
          this.endDays.giovedi2 = this.endDays.giovedi2
            ? this.endDays.giovedi2
            : "chiuso";
          this.days.gio = (
            this.startDays.giovedi +
            "-" +
            this.endDays.giovedi +
            " " +
            this.startDays.giovedi2 +
            "-" +
            this.endDays.giovedi2
          ).toString();
        }
        /*  else {
            alert(
              "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
            );
          }*/
      }
    }
    if (this.fasceOrarioVenerdi == "Unico turno/Continuato") {
      if (this.nameDay == "Venerdi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.ven = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCVE1 = new Date("01/01/2200 " + this.startChiusura);
          var endCVE1 = new Date("01/01/2200 " + this.endChiusura);
          var time1VE1 = new Date("01/01/2200 " + this.startDays.venerdi);
          var time2VE1 = new Date("01/01/2200 " + this.endDays.venerdi);
          if (startCVE1 < time1VE1) {
            if (endCVE1 < time1VE1) {
              this.days.ven = (
                this.startDays.venerdi +
                "-" +
                this.endDays.venerdi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCVE1 >= time1VE1) {
              if (endCVE1 < time2VE1) {
                this.days.ven = (
                  this.endChiusura +
                  "-" +
                  this.endDays.venerdi +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCVE1 >= time2VE1) {
                this.days.ven = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCVE1 > time1VE1) {
            if (startCVE1 < time2VE1) {
              if (endCVE1 >= time2VE1) {
                this.days.ven = (
                  this.startDays.venerdi +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCVE1 < time2VE1) {
                this.fasceOrarioVenerdi = "A turni";
                this.days.ven = (
                  this.startDays.venerdi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.venerdi
                ).toString();
              }
            } else if (startCVE1 >= time2VE1) {
              this.days.ven = (
                this.startDays.venerdi +
                "-" +
                this.endDays.venerdi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.venerdi) {
            if (this.endChiusura == this.endDays.venerdi) {
              this.days.ven = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCVE1 > time2VE1) {
              this.days.ven = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCVE1 < time2VE1) {
              this.days.ven = (
                this.endChiusura +
                "-" +
                this.endDays.venerdi +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.venerdi = this.startDays.venerdi
          ? this.startDays.venerdi
          : "chiuso";
        this.endDays.venerdi = this.endDays.venerdi
          ? this.endDays.venerdi
          : "chiuso";
        this.days.ven = (
          this.startDays.venerdi +
          "-" +
          this.endDays.venerdi +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioVenerdi == "A turni") {
      if (this.nameDay == "Venerdi") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.ven = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCVE = new Date("01/01/2100 " + this.startChiusura);
          var endCVE = new Date("01/01/2100 " + this.endChiusura);
          var time1VE = new Date("01/01/2100 " + this.startDays.venerdi);
          var time2VE = new Date("01/01/2100 " + this.endDays.venerdi);
          var time3VE = new Date("01/01/2100 " + this.startDays.venerdi2);
          var time4VE = new Date("01/01/2100 " + this.endDays.venerdi2);
          if (startCVE < time1VE) {
            if (endCVE > time2VE) {
              this.days.ven = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.venerdi2
              ).toString();
            } else if (endCVE < time2VE) {
              this.days.ven = (
                this.endChiusura +
                "-" +
                this.endDays.venerdi +
                " " +
                this.startDays.venerdi2 +
                "-" +
                this.endDays.venerdi2
              ).toString();
            } else if (endCVE == time2VE) {
              this.days.ven = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.venerdi2
              ).toString();
            }
          } else if (startCVE > time1VE) {
            if (startCVE >= time2VE) {
              if (endCVE < time3VE) {
                this.days.ven = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.venerdi2
                ).toString();
              } else if (endCVE > time3VE) {
                if (endCVE > time4VE) {
                  this.days.ven = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCVE < time4VE) {
                  this.days.ven = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.venerdi2
                  ).toString();
                }
              }
            } else if (startCVE < time2VE) {
              if (endCVE < time2VE) {
                this.days.ven = (
                  this.endDays.venerdi +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.venerdi2
                ).toString();
              } else if (endCVE > time2VE) {
                if (endCVE < time3VE) {
                  this.days.ven = (
                    this.endDays.venerdi +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.venerdi2
                  ).toString();
                } else if (endCVE > time3VE) {
                  if (endCVE < time4VE) {
                    this.days.ven = (
                      this.endDays.venerdi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.venerdi2
                    ).toString();
                  } else if (endCVE > time4VE) {
                    this.days.ven = (
                      this.endDays.venerdi +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (ven < 0 && ven2 < 0) {
          this.startDays.venerdi = this.startDays.venerdi
            ? this.startDays.venerdi
            : "chiuso";
          this.endDays.venerdi = this.endDays.venerdi
            ? this.endDays.venerdi
            : "chiuso";
          this.startDays.venerdi2 = this.startDays.venerdi2
            ? this.startDays.venerdi2
            : "chiuso";
          this.endDays.venerdi2 = this.endDays.venerdi2
            ? this.endDays.venerdi2
            : "chiuso";
          this.days.ven = (
            this.startDays.venerdi +
            "-" +
            this.endDays.venerdi +
            " " +
            this.startDays.venerdi2 +
            "-" +
            this.endDays.venerdi2
          ).toString();
        }
        /*  else {
            alert(
              "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
            );
          }*/
      }
    }
    if (this.fasceOrarioSabato == "Unico turno/Continuato") {
      if (this.nameDay == "Sabato") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.sab = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCSA1 = new Date("01/01/2200 " + this.startChiusura);
          var endCSA1 = new Date("01/01/2200 " + this.endChiusura);
          var time1SA1 = new Date("01/01/2200 " + this.startDays.sabato);
          var time2SA1 = new Date("01/01/2200 " + this.endDays.sabato);
          if (startCSA1 < time1SA1) {
            if (endCSA1 < time1SA1) {
              this.days.sab = (
                this.startDays.sabato +
                "-" +
                this.endDays.sabato +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCSA1 >= time1SA1) {
              if (endCSA1 < time2SA1) {
                this.days.sab = (
                  this.endChiusura +
                  "-" +
                  this.endDays.sabato +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCSA1 >= time2SA1) {
                this.days.sab = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCSA1 > time1SA1) {
            if (startCSA1 < time2SA1) {
              if (endCSA1 >= time2SA1) {
                this.days.sab = (
                  this.startDays.sabato +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCSA1 < time2SA1) {
                this.fasceOrarioSabato = "A turni";
                this.days.sab = (
                  this.startDays.sabato +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.sabato
                ).toString();
              }
            } else if (startCSA1 >= time2SA1) {
              this.days.sab = (
                this.startDays.sabato +
                "-" +
                this.endDays.sabato +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.sabato) {
            if (this.endChiusura == this.endDays.sabato) {
              this.days.sab = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCSA1 > time2SA1) {
              this.days.sab = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCSA1 < time2SA1) {
              this.days.sab = (
                this.endChiusura +
                "-" +
                this.endDays.sabato +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.sabato = this.startDays.sabato
          ? this.startDays.sabato
          : "chiuso";
        this.endDays.sabato = this.endDays.sabato
          ? this.endDays.sabato
          : "chiuso";
        this.days.sab = (
          this.startDays.sabato +
          "-" +
          this.endDays.sabato +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioSabato == "A turni") {
      if (this.nameDay == "Sabato") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.sab = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCSA = new Date("01/01/2100 " + this.startChiusura);
          var endCSA = new Date("01/01/2100 " + this.endChiusura);
          var time1SA = new Date("01/01/2100 " + this.startDays.sabato);
          var time2SA = new Date("01/01/2100 " + this.endDays.sabato);
          var time3SA = new Date("01/01/2100 " + this.startDays.sabato2);
          var time4SA = new Date("01/01/2100 " + this.endDays.sabato2);
          if (startCSA < time1SA) {
            if (endCSA > time2SA) {
              this.days.sab = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.sabato2
              ).toString();
            } else if (endCSA < time2SA) {
              this.days.sab = (
                this.endChiusura +
                "-" +
                this.endDays.sabato +
                " " +
                this.startDays.sabato2 +
                "-" +
                this.endDays.sabato2
              ).toString();
            } else if (endCSA == time2SA) {
              this.days.sab = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.sabato2
              ).toString();
            }
          } else if (startCSA > time1SA) {
            if (startCSA >= time2SA) {
              if (endCSA < time3SA) {
                this.days.sab = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.sabato2
                ).toString();
              } else if (endCSA > time3SA) {
                if (endCSA > time4SA) {
                  this.days.sab = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCSA < time4SA) {
                  this.days.sab = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.sabato2
                  ).toString();
                }
              }
            } else if (startCSA < time2SA) {
              if (endCSA < time2SA) {
                this.days.sab = (
                  this.endDays.sabato +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.sabato2
                ).toString();
              } else if (endCSA > time2SA) {
                if (endCSA < time3SA) {
                  this.days.sab = (
                    this.endDays.sabato +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.sabato2
                  ).toString();
                } else if (endCSA > time3SA) {
                  if (endCSA < time4SA) {
                    this.days.sab = (
                      this.endDays.sabato +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.sabato2
                    ).toString();
                  } else if (endCSA > time4SA) {
                    this.days.sab = (
                      this.endDays.sabato +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (sab < 0 && sab2 < 0) {
          this.startDays.sabato = this.startDays.sabato
            ? this.startDays.sabato
            : "chiuso";
          this.endDays.sabato = this.endDays.sabato
            ? this.endDays.sabato
            : "chiuso";
          this.startDays.sabato2 = this.startDays.sabato2
            ? this.startDays.sabato2
            : "chiuso";
          this.endDays.sabato2 = this.endDays.sabato2
            ? this.endDays.sabato2
            : "chiuso";
          this.days.sab = (
            this.startDays.sabato +
            "-" +
            this.endDays.sabato +
            " " +
            this.startDays.sabato2 +
            "-" +
            this.endDays.sabato2
          ).toString();
        }
        /*  else {
            alert(
              "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
            );
          }*/
      }
    }
    if (this.fasceOrarioDomenica == "Unico turno/Continuato") {
      if (this.nameDay == "Domenica") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.dom = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCDO1 = new Date("01/01/2200 " + this.startChiusura);
          var endCDO1 = new Date("01/01/2200 " + this.endChiusura);
          var time1DO1 = new Date("01/01/2200 " + this.startDays.domenica);
          var time2DO1 = new Date("01/01/2200 " + this.endDays.domenica);
          if (startCDO1 < time1DO1) {
            if (endCDO1 < time1DO1) {
              this.days.dom = (
                this.startDays.domenica +
                "-" +
                this.endDays.domenica +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCDO1 >= time1DO1) {
              if (endCDO1 < time2DO1) {
                this.days.dom = (
                  this.endChiusura +
                  "-" +
                  this.endDays.domenica +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCDO1 >= time2DO1) {
                this.days.dom = (
                  "chiuso" +
                  "-" +
                  "chiuso" +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              }
            }
          } else if (startCDO1 > time1DO1) {
            if (startCDO1 < time2DO1) {
              if (endCDO1 >= time2DO1) {
                this.days.dom = (
                  this.startDays.domenica +
                  "-" +
                  this.startChiusura +
                  " " +
                  "chiuso" +
                  "-" +
                  "chiuso"
                ).toString();
              } else if (endCDO1 < time2DO1) {
                this.fasceOrarioDomenica = "A turni";
                this.days.dom = (
                  this.startDays.domenica +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.domenica
                ).toString();
              }
            } else if (startCDO1 >= time2DO1) {
              this.days.dom = (
                this.startDays.domenica +
                "-" +
                this.endDays.domenica +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          } else if (this.startChiusura == this.startDays.domenica) {
            if (this.endChiusura == this.endDays.domenica) {
              this.days.dom = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCDO1 > time2DO1) {
              this.days.dom = (
                "chiuso" +
                "-" +
                "chiuso" +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            } else if (endCDO1 < time2DO1) {
              this.days.dom = (
                this.endChiusura +
                "-" +
                this.endDays.domenica +
                " " +
                "chiuso" +
                "-" +
                "chiuso"
              ).toString();
            }
          }
        }
      } else {
        this.startDays.domenica = this.startDays.domenica
          ? this.startDays.domenica
          : "chiuso";
        this.endDays.domenica = this.endDays.domenica
          ? this.endDays.domenica
          : "chiuso";
        this.days.dom = (
          this.startDays.domenica +
          "-" +
          this.endDays.domenica +
          " " +
          "chiuso" +
          "-" +
          "chiuso"
        ).toString();
      }
    } else if (this.fasceOrarioDomenica == "A turni") {
      if (this.nameDay == "Domenica") {
        if (this.startChiusura == "00:00" && this.endChiusura == "23:59") {
          this.days.dom = (
            "chiuso" +
            "-" +
            "chiuso" +
            " " +
            "chiuso" +
            "-" +
            "chiuso"
          ).toString();
        } else {
          var startCDO = new Date("01/01/2100 " + this.startChiusura);
          var endCDO = new Date("01/01/2100 " + this.endChiusura);
          var time1DO = new Date("01/01/2100 " + this.startDays.domenica);
          var time2DO = new Date("01/01/2100 " + this.endDays.domenica);
          var time3DO = new Date("01/01/2100 " + this.startDays.domenica2);
          var time4DO = new Date("01/01/2100 " + this.endDays.domenica2);
          if (startCDO < time1DO) {
            if (endCDO > time2DO) {
              this.days.dom = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.domenica2
              ).toString();
            } else if (endCDO < time2DO) {
              this.days.dom = (
                this.endChiusura +
                "-" +
                this.endDays.domenica +
                " " +
                this.startDays.domenica2 +
                "-" +
                this.endDays.domenica2
              ).toString();
            } else if (endCDO == time2DO) {
              this.days.dom = (
                "00:00" +
                "-" +
                this.startChiusura +
                " " +
                this.endChiusura +
                "-" +
                this.endDays.domenica2
              ).toString();
            }
          } else if (startCDO > time1DO) {
            if (startCDO >= time2DO) {
              if (endCDO < time3DO) {
                this.days.dom = (
                  "00:00" +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.domenica2
                ).toString();
              } else if (endCDO > time3DO) {
                if (endCDO > time4DO) {
                  this.days.dom = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    "23:59"
                  ).toString();
                } else if (endCDO < time4DO) {
                  this.days.dom = (
                    "00:00" +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.domenica2
                  ).toString();
                }
              }
            } else if (startCDO < time2DO) {
              if (endCDO < time2DO) {
                this.days.dom = (
                  this.endDays.domenica +
                  "-" +
                  this.startChiusura +
                  " " +
                  this.endChiusura +
                  "-" +
                  this.endDays.domenica2
                ).toString();
              } else if (endCDO > time2DO) {
                if (endCDO < time3DO) {
                  this.days.dom = (
                    this.endDays.domenica +
                    "-" +
                    this.startChiusura +
                    " " +
                    this.endChiusura +
                    "-" +
                    this.endDays.domenica2
                  ).toString();
                } else if (endCDO > time3DO) {
                  if (endCDO < time4DO) {
                    this.days.dom = (
                      this.endDays.domenica +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      this.endDays.domenica2
                    ).toString();
                  } else if (endCDO > time4DO) {
                    this.days.dom = (
                      this.endDays.domenica +
                      "-" +
                      this.startChiusura +
                      " " +
                      this.endChiusura +
                      "-" +
                      "23:59"
                    ).toString();
                  }
                }
              }
            }
          }
        }
      } else {
        if (dom <= 0 && dom2 < 0) {
          this.startDays.domenica = this.startDays.domenica
            ? this.startDays.domenica
            : "chiuso";
          this.endDays.domenica = this.endDays.domenica
            ? this.endDays.domenica
            : "chiuso";
          this.startDays.domenica2 = this.startDays.domenica2
            ? this.startDays.domenica2
            : "chiuso";
          this.endDays.domenica2 = this.endDays.domenica2
            ? this.endDays.domenica2
            : "chiuso";
          this.days.dom = (
            this.startDays.domenica +
            "-" +
            this.endDays.domenica +
            " " +
            this.startDays.domenica2 +
            "-" +
            this.endDays.domenica2
          ).toString();
        }
      }
    }
    this.days.exc = this.listaEccezioni;
    this.days.id = this.user.id;

    if (this.days.lun == "chiuso-chiuso chiuso-chiuso") {
      this.days.lun = "Chiuso";
      lun = -2;
      lun2 = -2;
    }
    if (this.days.lun.split(" ")[1] == "chiuso-chiuso") {
      this.days.lun = this.days.lun.split(" ")[0] + " ";
    }
    if (this.days.mar == "chiuso-chiuso chiuso-chiuso") {
      this.days.mar = "Chiuso";
      mar = -2;
      mar2 = -2;
    }
    if (this.days.mar.split(" ")[1] == "chiuso-chiuso") {
      this.days.mar = this.days.mar.split(" ")[0] + " ";
    }
    if (this.days.mer == "chiuso-chiuso chiuso-chiuso") {
      this.days.mer = "Chiuso";
      mer = -2;
      mer2 = -2;
    }
    if (this.days.mer.split(" ")[1] == "chiuso-chiuso") {
      this.days.mer = this.days.mer.split(" ")[0] + " ";
    }
    if (this.days.gio == "chiuso-chiuso chiuso-chiuso") {
      this.days.gio = "Chiuso";
      gio = -2;
      gio2 = -2;
    }
    if (this.days.gio.split(" ")[1] == "chiuso-chiuso") {
      this.days.gio = this.days.gio.split(" ")[0] + " ";
    }
    if (this.days.ven == "chiuso-chiuso chiuso-chiuso") {
      this.days.ven = "Chiuso";
      ven = -2;
      ven2 = -2;
    }
    if (this.days.ven.split(" ")[1] == "chiuso-chiuso") {
      this.days.ven = this.days.ven.split(" ")[0] + " ";
    }
    if (this.days.sab == "chiuso-chiuso chiuso-chiuso") {
      this.days.sab = "Chiuso";
      sab = -2;
      sab2 = -2;
    }
    if (this.days.sab.split(" ")[1] == "chiuso-chiuso") {
      this.days.sab = this.days.sab.split(" ")[0] + " ";
    }
    if (this.days.dom == "chiuso-chiuso chiuso-chiuso") {
      this.days.dom = "Chiuso";
      dom = -2;
      dom2 = -2;
    }
    if (this.days.dom.split(" ")[1] == "chiuso-chiuso") {
      this.days.dom = this.days.dom.split(" ")[0] + " ";
    }
    if (
      this.days.lun == "Chiuso" &&
      this.days.mar == "Chiuso" &&
      this.days.mer == "Chiuso" &&
      this.days.gio == "Chiuso" &&
      this.days.ven == "Chiuso" &&
      this.days.sab == "Chiuso" &&
      this.days.dom == "Chiuso"
    ) {
      lun = 3;
      lun2 = -3;
      mar = 3;
      mar2 = -3;
      mer = 3;
      mer2 = -3;
      gio = 3;
      gio2 = -3;
      ven = 3;
      ven2 = -3;
      sab = 3;
      sab2 = -3;
      dom = 3;
      dom2 = -3;
    }

    if (lun.toString() == "NaN") {
      lun = -1;
    }
    if (lun2.toString() == "NaN") {
      lun2 = -1;
    }
    if (mar.toString() == "NaN") {
      mar = -1;
    }
    if (mar2.toString() == "NaN") {
      mar2 = -1;
    }
    if (mer.toString() == "NaN") {
      mer = -1;
    }
    if (mer2.toString() == "NaN") {
      mer2 = -1;
    }
    if (gio.toString() == "NaN") {
      gio = -1;
    }
    if (gio2.toString() == "NaN") {
      gio2 = -1;
    }
    if (ven.toString() == "NaN") {
      ven = -1;
    }
    if (ven2.toString() == "NaN") {
      ven2 = -1;
    }
    if (sab.toString() == "NaN") {
      sab = -1;
    }
    if (sab2.toString() == "NaN") {
      sab2 = -1;
    }
    if (dom.toString() == "NaN") {
      dom = -1;
    }
    if (dom2.toString() == "NaN") {
      dom2 = -1;
    }
    var lunediEnd = new Date("01/01/2200 " + this.endDays.lunedi);
    var lunediStart = new Date("01/01/2200 " + this.startDays.lunedi2);
    var martediEnd = new Date("01/01/2200 " + this.endDays.martedi);
    var martediStart = new Date("01/01/2200 " + this.startDays.martedi2);
    var mercolediEnd = new Date("01/01/2200 " + this.endDays.mercoledi);
    var mercolediStart = new Date("01/01/2200 " + this.startDays.mercoledi2);
    var giovediEnd = new Date("01/01/2200 " + this.endDays.giovedi);
    var giovediStart = new Date("01/01/2200 " + this.startDays.giovedi2);
    var venerdiEnd = new Date("01/01/2200 " + this.endDays.venerdi);
    var venerdiStart = new Date("01/01/2200 " + this.startDays.venerdi2);
    var sabatoEnd = new Date("01/01/2200 " + this.endDays.sabato);
    var sabatoStart = new Date("01/01/2200 " + this.startDays.sabato2);
    var domenicaEnd = new Date("01/01/2200 " + this.endDays.domenica);
    var domenicaStart = new Date("01/01/2200 " + this.startDays.domenica2);
    if (lunediStart <= lunediEnd && this.fasceOrarioLunedi == "A turni") {
      lun = 3;
      lun2 = -3;
    }
    if (martediStart <= martediEnd && this.fasceOrarioMartedi == "A turni") {
      mar = 3;
      mar2 = -3;
    }
    if (
      mercolediStart <= mercolediEnd &&
      this.fasceOrarioMercoledi == "A turni"
    ) {
      mer = 3;
      mer2 = -3;
    }
    if (giovediStart <= giovediEnd && this.fasceOrarioGiovedi == "A turni") {
      gio = 3;
      gio2 = -3;
    }
    if (venerdiStart <= venerdiEnd && this.fasceOrarioVenerdi == "A turni") {
      ven = 3;
      ven2 = -3;
    }
    if (sabatoStart <= sabatoEnd && this.fasceOrarioSabato == "A turni") {
      sab = 3;
      sab2 = -3;
    }
    if (domenicaStart <= domenicaEnd && this.fasceOrarioDomenica == "A turni") {
      dom = 3;
      dom2 = -3;
    }
    //console.log(this.endDays.giovedi.toString());
    if (
      lun != -1 &&
      lun < 0 &&
      lun2 < 0 &&
      mar != -1 &&
      mar < 0 &&
      mar2 < 0 &&
      mer != -1 &&
      mer < 0 &&
      mer2 < 0 &&
      gio != -1 &&
      gio < 0 &&
      gio2 < 0 &&
      ven != -1 &&
      ven < 0 &&
      ven2 < 0 &&
      sab != -1 &&
      sab < 0 &&
      sab2 < 0 &&
      dom != -1 &&
      dom < 0 &&
      dom2 < 0
    ) {
      this.calendarService.setCloseDay(this.days).subscribe((x) => {
        alert("Orario impostato correttamente!");
        this.listaEccezioni = [];
        this.getShopCalendar();
        this.nameDay = "";
      });
    } else if (
      (lun < 0 && lun2 >= 0) ||
      (mar < 0 && mar2 >= 0) ||
      (mer < 0 && mer2 >= 0) ||
      (gio < 0 && gio2 >= 0) ||
      (ven < 0 && ven2 >= 0) ||
      (sab < 0 && sab2 >= 0) ||
      (dom < 0 && dom2 >= 0)
    ) {
      alert(
        "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
      );
    } else if (
      (lun >= 0 && lun2 < 0) ||
      (mar >= 0 && mar2 < 0) ||
      (mer >= 0 && mer2 < 0) ||
      (gio >= 0 && gio2 < 0) ||
      (ven >= 0 && ven2 < 0) ||
      (sab >= 0 && sab2 < 0) ||
      (dom >= 0 && dom2 < 0)
    ) {
      //alert("Attenzione! Controllare gli orari inseriti.");
      this.calendarService.setCloseDay(this.days).subscribe((x) => {
        alert("Orario impostato correttamente!");
        this.listaEccezioni = [];
        this.getShopCalendar();
        this.nameDay = "";
      });
    } else if (
      (lun == -1 && lun2 < 0) ||
      (mar == -1 && mar2 < 0) ||
      (mer == -1 && mer2 < 0) ||
      (gio == -1 && gio2 < 0) ||
      (ven == -1 && ven2 < 0) ||
      (sab == -1 && sab2 < 0) ||
      (dom == -1 && dom2 < 0)
    ) {
      alert(
        "Attenzione! L'opzione 'A turni' prevede la compilazione di tutti i campi."
      );
    }
  }

  eliminaOrario(days) {
    let d = days.date.split("T")[0];
    var domanda = confirm(
      "Vuoi eliminare il turno in data " +
      d.split("-")[2] +
      "/" +
      d.split("-")[1] +
      "/" +
      d.split("-")[0] +
      "?"
    );
    if (domanda == true) {
      this.calendarService.deleteCloseDay(days.id).subscribe((x) => {
        this.getShopCalendar();
      });
    }
  }

  aggiornaUtente() {
    if (this.user.fiscalCode)
      this.user.fiscalCode = this.user.fiscalCode.toUpperCase();
    if (this.userService.currentUserValue.role == Role.Manager) {
      this.user.active = true;

      this.user.born = this.user.born.includes("T")
        ? this.user.born
        : this.user.born + "T12:00:00";
      this.userService.update(this.user).subscribe(
        (x) => {
          this.user.born = this.user.born.split("T")[0];
          this.spinner.show();
          if (this.user.fiscalCode) {
            this.userService
              .setFiscalCode(
                +this.route.snapshot.paramMap.get("id"),
                this.user.fiscalCode
              )
              .subscribe((x) => {
                window.alert("Utente modificato correttamente!");
              });
          } else {
            alert("Utente modificato correttamente!");
          }
          this.spinner.hide();
        },
        (_) => {
          window.alert("La modifica non è andata a buon fine, riprova");
        }
      );
    } else {
      if (this.user.fiscalCode) {
        this.spinner.show();
        this.userService
          .setFiscalCode(
            +this.route.snapshot.paramMap.get("id"),
            this.user.fiscalCode
          )
          .subscribe((x) => {
            this.spinner.hide();
            alert("Codice fiscale aggiornato!");
          });
      }
    }
  };

  validaOrari(orario1, orario2) {
    // Controllo che entrambi gli orari siano valorizzati
    if (!orario1 || !orario2) {
      return false; // Se uno dei due orari non è valorizzato, ritorna false
    }

    // Parsing degli orari (esempio: "HH:MM")
    const [ore1, minuti1] = orario1.split(':').map(Number);
    const [ore2, minuti2] = orario2.split(':').map(Number);

    // Controllo che gli orari siano compresi tra 00:00 e 23:59
    if (ore1 < 0 || ore1 > 23 || minuti1 < 0 || minuti1 > 59 ||
      ore2 < 0 || ore2 > 23 || minuti2 < 0 || minuti2 > 59) {
      return false;
    }

    // Confronto gli orari per verificare che il secondo sia dopo il primo
    if (ore2 < ore1 || (ore2 === ore1 && minuti2 <= minuti1)) {
      return false; // orario2 non è successivo a orario1
    }

    return true; // Tutti i controlli sono passati
  };

  getTelegramCode(id) {
    this.farmaciaService.getTelegramCode().subscribe((x) => {
      this.user.telegramCode = id + "-" + x.telegramCode;
    });
  }
  getShopCalendar() {
    this.Chiusura = false;
    this.Diurno = false;
    this.Notturno = false;
    this.calendarService.getCloseDay(this.user.id).subscribe(
      (x) => {
        this.days = x;
        this.listaChisure = x.exc.filter(elem => { return elem.openingDay.includes("CH") })
        this.listaFerie = this.days.exc.sort(function (a, b) {
          return a.date < b.date ? 1 : -1;
        });;

        this.arrayFerie = this.listaFerie
          .slice((0 + 1 - 1) * this.pageSize)
          .slice(0, this.pageSize);
        this.pageLength = this.listaFerie.length;
        if (this.days != null) {
          if (this.days.lun == "Chiuso") {
            this.days.lun = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.lun.split(" ")[2] == "") {
            this.days.lun + " chiuso-chiuso";
          } else {
            this.startDays.lunedi = this.days.lun.split(/[-]|[ ]/g)[0];
            this.endDays.lunedi = this.days.lun.split(/[-]|[ ]/g)[1];
            this.startDays.lunedi2 = this.days.lun.split(/[-]|[ ]/g)[2];
            this.endDays.lunedi2 = this.days.lun.split(/[-]|[ ]/g)[3];
          }

          if (this.days.mar == "Chiuso") {
            this.days.mar = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.mar.split(" ")[2] == "") {
            this.days.mar + " chiuso-chiuso";
          } else {
            this.startDays.martedi = this.days.mar.split(/[-]|[ ]/g)[0];
            this.endDays.martedi = this.days.mar.split(/[-]|[ ]/g)[1];
            this.startDays.martedi2 = this.days.mar.split(/[-]|[ ]/g)[2];
            this.endDays.martedi2 = this.days.mar.split(/[-]|[ ]/g)[3];
          }
          if (this.days.mer == "Chiuso") {
            this.days.mer = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.mer.split(" ")[2] == "") {
            this.days.mer + " chiuso-chiuso";
          } else {
            this.startDays.mercoledi = this.days.mer.split(/[-]|[ ]/g)[0];
            this.endDays.mercoledi = this.days.mer.split(/[-]|[ ]/g)[1];
            this.startDays.mercoledi2 = this.days.mer.split(/[-]|[ ]/g)[2];
            this.endDays.mercoledi2 = this.days.mer.split(/[-]|[ ]/g)[3];
          }
          if (this.days.gio == "Chiuso") {
            this.days.gio = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.gio.split(" ")[2] == "") {
            this.days.gio + " chiuso-chiuso";
          } else {
            this.startDays.giovedi = this.days.gio.split(/[-]|[ ]/g)[0];
            this.endDays.giovedi = this.days.gio.split(/[-]|[ ]/g)[1];
            this.startDays.giovedi2 = this.days.gio.split(/[-]|[ ]/g)[2];
            this.endDays.giovedi2 = this.days.gio.split(/[-]|[ ]/g)[3];
          }
          if (this.days.ven == "Chiuso") {
            this.days.ven = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.ven.split(" ")[2] == "") {
            this.days.ven + " chiuso-chiuso";
          } else {
            this.startDays.venerdi = this.days.ven.split(/[-]|[ ]/g)[0];
            this.endDays.venerdi = this.days.ven.split(/[-]|[ ]/g)[1];
            this.startDays.venerdi2 = this.days.ven.split(/[-]|[ ]/g)[2];
            this.endDays.venerdi2 = this.days.ven.split(/[-]|[ ]/g)[3];
          }
          if (this.days.sab == "Chiuso") {
            this.days.sab = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.sab.split(" ")[2] == "") {
            this.days.sab + " chiuso-chiuso";
          } else {
            this.startDays.sabato = this.days.sab.split(/[-]|[ ]/g)[0];
            this.endDays.sabato = this.days.sab.split(/[-]|[ ]/g)[1];
            this.startDays.sabato2 = this.days.sab.split(/[-]|[ ]/g)[2];
            this.endDays.sabato2 = this.days.sab.split(/[-]|[ ]/g)[3];
          }
          if (this.days.dom == "Chiuso") {
            this.days.dom = "chiuso-chiuso chiuso-chiuso";
          }
          if (this.days.dom.split(" ")[2] == "") {
            this.days.dom + " chiuso-chiuso";
          } else {
            this.startDays.domenica = this.days.dom.split(/[-]|[ ]/g)[0];
            this.endDays.domenica = this.days.dom.split(/[-]|[ ]/g)[1];
            this.startDays.domenica2 = this.days.dom.split(/[-]|[ ]/g)[2];
            this.endDays.domenica2 = this.days.dom.split(/[-]|[ ]/g)[3];
          }

          console.log(this.days.lun.split(/[-]|[ ]/g[2]));

          if (
            this.days.lun.split(/[-]|[ ]/g)[2] == "" ||
            this.days.lun ==
            "chiuso-chiuso chiuso-chiuso" /*  || this.days.lun.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.lun.split(/[-]|[ ]/g)[3] == " " || this.days.lun.split(/[-]|[ ]/g)[3] == "chiuso") */
          ) {
            this.fasceOrarioLunedi = "Unico turno/Continuato";
          }
          if (
            this.days.mar.split(/[-]|[ ]/g)[2] == "" ||
            this.days.mar == "chiuso-chiuso chiuso-chiuso"
          ) {
            //|| this.days.mar.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.mar.split(/[-]|[ ]/g)[3] == " " || this.days.mar.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioMartedi = "Unico turno/Continuato";
          }
          if (
            this.days.mer.split(/[-]|[ ]/g)[2] == "" ||
            this.days.mer == "chiuso-chiuso chiuso-chiuso"
          ) {
            // || this.days.mer.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.mer.split(/[-]|[ ]/g)[3] == " " || this.days.mer.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioMercoledi = "Unico turno/Continuato";
          }
          if (
            this.days.gio.split(/[-]|[ ]/g)[2] == "" ||
            this.days.gio == "chiuso-chiuso chiuso-chiuso"
          ) {
            //|| this.days.gio.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.gio.split(/[-]|[ ]/g)[3] == " " || this.days.gio.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioGiovedi = "Unico turno/Continuato";
          }
          if (
            this.days.ven.split(/[-]|[ ]/g)[2] == "" ||
            this.days.ven == "chiuso-chiuso chiuso-chiuso"
          ) {
            // || this.days.ven.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.ven.split(/[-]|[ ]/g)[3] == " " || this.days.ven.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioVenerdi = "Unico turno/Continuato";
          }
          if (
            this.days.sab.split(/[-]|[ ]/g)[2] == "" ||
            this.days.sab == "chiuso-chiuso chiuso-chiuso"
          ) {
            //|| this.days.sab.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.sab.split(/[-]|[ ]/g)[3] == " " || this.days.sab.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioSabato = "Unico turno/Continuato";
          }
          if (
            this.days.dom.split(/[-]|[ ]/g)[2] == "" ||
            this.days.dom == "chiuso-chiuso chiuso-chiuso"
          ) {
            // || this.days.dom.split(/[-]|[ ]/g)[2] == "chiuso") && (this.days.dom.split(/[-]|[ ]/g)[3] == " " || this.days.dom.split(/[-]|[ ]/g)[3] == "chiuso")) {
            this.fasceOrarioDomenica = "Unico turno/Continuato";
          }
          //console.log(this.days.lun.split(/[-]|[ ]/g));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  /*   handleDateClick(arg) {
    // handler method
    if (
      arg.dateStr >= this.minDate &&
      (this.userService.currentUserValue.role === Role.Employee ||
        this.userService.currentUserValue.role === Role.Manager)
    ) {
      const id = this.route.snapshot.paramMap.get("id");
      this.router.navigate(["/calendario/new"], {
        queryParams: { idshop: id, date: arg.dateStr },
      });
    }
  } */
  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.arrayFerie = this.listaFerie.slice(offset).slice(0, event.pageSize);
  }
}

@Component({
  selector: "dialog-user",
  templateUrl: "./dialog-user.html",
})
export class DialogUser {
  userComponent;
  constructor(
    public dialogRef: MatDialogRef<DialogUser>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }
  change(valore) {
    if (valore == false) {
      this.data.startChiusura = "00:00";
      this.data.endChiusura = "23:59";
    } else if (valore == true) {
      this.data.startChiusura = "--:--";
      this.data.endChiusura = "--:--";
    }
  }
}

@Component({
  selector: "dialog-telegram-user",
  templateUrl: "./dialog-user-telegramList.html",
})
export class DialogUserTelegram implements OnInit {
  userComponent;
  users: [];
  constructor(
    public dialogRef: MatDialogRef<DialogUserTelegram>,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogTelegram
  ) { }

  ngOnInit() {
    this.userService.getTelegramUsersList(this.data.idShop).subscribe((x) => {
      this.users = x;
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  onConfirm() {
    this.dialogRef.close();
  }

  eliminaUtente(user, index) {
    let r = confirm(
      "Sei sicuro di voler eliminare l'utente " +
      user.idTelegram +
      " dalla lista?"
    );
    if (r) {
      this.userService.deleteTelegramUserFromList(user.idTelegram).subscribe(
        (x) => {
          this.users.splice(index, 1);
          alert("Utente eliminato correttamente!");
        },
        (err) => {
          if (err.text.includes("cancellato con successo!")) {
            this.users.splice(index, 1);
            alert("Utente eliminato correttamente!");
          }
        }
      );
    } else {
    }
  }
}
