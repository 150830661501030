import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { apiUrl } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DoctorServices {

    constructor(private http: HttpClient,
        private userService: UserService) { }

    httpOptions = {
        headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authoritation: "Bearer-token" + this.userService.currentUserValue.token,
        }),
    };


    addDoctorService(serviceBody, photo): Promise<any> {

        var formdata = new FormData();
        formdata.append("serviceInShopToSaveRequest", JSON.stringify(serviceBody))
        formdata.append("image", photo)

        var requestOptions = {
            method: "POST",
            body: formdata,
            headers: new Headers({
                Authorization: "Bearer " + this.userService.currentUserValue.token,
            }),
        };

        return fetch(`${apiUrl}/serviceInShop/create`, requestOptions);

    }

    addSubServiceToParent(serviceListBody, serviceInShopParentId): Observable<any> {

        var requestOptions = {
            headers: {
                Authorization: "Bearer " + this.userService.currentUserValue.token,
            },
        };

        return this.http.post(`${apiUrl}/serviceInShop/add/${serviceInShopParentId}`, serviceListBody, requestOptions);

    }

    getServices(serviceType: number): Observable<any> {
        return this.http.get(`${apiUrl}/serviceInShop/getByServiceType/${serviceType}`, this.httpOptions)
            .pipe();
    }

    getServicesByPage( serviceType: number,page: number,size:number): Observable<any> {
        return this.http
            .get(
                `${apiUrl}/serviceInShop/getAll/${serviceType}?page=${page}&size=${size}`
            )
            .pipe();
    }


    /*   getServiceDetail(serviceId: number): Observable<any> {
          return this.http.get(`${apiUrl}/getServiceDetails/${serviceId}`)
              .pipe();
      } */


    modifyDoctorService(serviceBody, photo?, serviceId?): Promise<any> {

        var formdata = new FormData();
        formdata.append("serviceInShopToUpdateRequest", JSON.stringify(serviceBody))
        formdata.append("image", photo)

        var requestOptions = {
            method: "PUT",
            body: formdata,
            headers: new Headers({
                Authorization: "Bearer " + this.userService.currentUserValue.token,
            }),
        };

        return fetch(`${apiUrl}/serviceInShop/modify/${serviceId}`, requestOptions);


    }


    deleteServiceById(serviceId: number): Observable<any> {
        return this.http.delete(`${apiUrl}/serviceInShop/delete/${serviceId}`);
    }


    getServiceDetail(idService) {
        return this.http.get<any>(`${apiUrl}/serviceInShop/get/${idService}`)
            .pipe();
    };
}