import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "src/app/models/User";
import { Location } from "@angular/common";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Role } from "src/app/enum/Role";
import { Sort } from "@angular/material/sort";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs/internal/Subscription";
import { MessagingService } from "src/app/messaging.service";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  nome: string;
  parolaChiaveNome: string;
  parolaChiaveCognome: string;
  nomeUtente: string;
  cognomeUtente: string;
  sessoUtente: string;
  indirizzoUtente: string;
  email: string;
  telefono: string;
  dataNascita: string;
  listaUtenti: User[];
  idProva: string;
  user: User;
  currentUser: JwtResponse;
  Role = Role;
  listautentiOrdinata: User[];
  showUtenti: boolean;
  idShop;
  listOrder$: Subscription;
  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private spinner: NgxSpinnerService,
    private msgService: MessagingService
  ) {
    let idUser;

    this.listOrder$ = this.msgService.currentMessage.subscribe(async (x) => {
      //console.log(x);
      if (x) {
        let id_shop = this.route.snapshot.paramMap.get("id");
        this.userService.getallUsers(+id_shop).subscribe((users) => {
          this.listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
          this.listaUtenti = this.listaUtenti.sort(function (a, b) {
            return a.id > b.id ? 1 : -1;
          });
          this.listautentiOrdinata = this.listaUtenti.slice();
          this.spinner.hide();
        });
      }
    });

    this.currentUser = this.userService.currentUserValue;
    this.userService
      .get(this.userService.currentUserValue.account)
      .subscribe((x) => {
        this.nome = x.name;
        idUser = x.id;
        if (
          this.route.snapshot.paramMap.get("id") != idUser &&
          this.userService.currentUserValue.role == Role.Employee
        ) {
          this.router.navigate(["/login"]);
        }
      });
  }

  ngOnInit() {
    this.spinner.show();
    this.showUsers();
    this.idShop = this.route.snapshot.paramMap.get("id");
  }

  dettaglioUtente(utente: User) {
    this.showUtenti = true;
    //console.log(utente.id);
    ("profiloutente/:id");
    window.open(`profiloutente/${utente.id}`);
  }

  showUsers() {
    this.showUtenti = false;
    const id_shop = this.route.snapshot.paramMap.get("id");
    this.userService.getallUsers(+id_shop).subscribe((users) => {
      this.listaUtenti = users.filter((e) => e.role != "ROLE_EMPLOYEE");
      this.listaUtenti = this.listaUtenti.sort(function (a, b) {
        return a.id > b.id ? 1 : -1;
      });
      this.listautentiOrdinata = this.listaUtenti.slice();
      this.spinner.hide();
    });
  }

  ordinalistaUtenti(sort: Sort) {
    const data = this.listaUtenti.slice();
    if (!sort.active || sort.direction === "") {
      this.listautentiOrdinata = data;
      return;
    }

    this.listautentiOrdinata = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "id":
          return compare(a.id, b.id, isAsc);
        /*       case "GitftCardSerial":
          return compare(a.GitftCardSerial, b.GitftCardSerial, isAsc); */
        case "email":
          return compare(a.email, b.email, isAsc);
        case "name":
          return compare(a.name, b.name, isAsc);
        case "surname":
          return compare(a.surname, b.surname, isAsc);
        case "phone":
          return compare(a.phone, b.phone, isAsc);
        case "address":
          return compare(a.address, b.address, isAsc);
        default:
          return 0;
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
