import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { CalendarioService } from "src/app/services/calendario.service";
import { EventoDettagli } from "src/app/models/eventoDettagli";
import { ActivatedRoute, Router } from "@angular/router";
import { Farmacia } from "src/app/models/Farmacia";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FullCalendarComponent } from "@fullcalendar/angular";
import timeGrigPlugin from "@fullcalendar/daygrid";
import itLocale from "@fullcalendar/core/locales/it";
import { UserService } from "../../services/user.service";
import { Location, formatDate } from "@angular/common";
import interactionPlugin from "@fullcalendar/interaction"; // for dateClick
import { Role } from "src/app/enum/Role";
import { NotificationServiceService } from "src/app/services/notification.service.service";
import { JwtResponse } from "src/app/response/JwtResponse";
import { Subject, Subscription } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { DoctorServices } from "src/app/services/doctorServices.service";
import { Lightbox } from "ngx-lightbox";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { animate, style, transition, trigger } from "@angular/animations";
import { debounceTime } from "rxjs/operators";

export interface DialogData {
  service;
}

@Component({
  selector: 'app-list-medici-in-zona',
  templateUrl: './list-medici-in-zona.component.html',
  styleUrls: ['./list-medici-in-zona.component.css']
})
export class ListMediciInZonaComponent implements OnInit {

  idShop;
  serviceList;
  serviceListOrdinata;
  page: any;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  size: number = 15;
  @Input() currentPage: number;
  querySub: Subscription;
  searchTermTemp;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private notificatioService: NotificationServiceService,
    private spinner: NgxSpinnerService,
    private doctorService: DoctorServices,
    private _lightbox: Lightbox,
    public dialog: MatDialog) { 

    }

  ngOnInit() {
    this.spinner.show();
    this.idShop = this.route.snapshot.paramMap.get("id");
    
    let nextPage = 1;
    
    if (this.route.snapshot.queryParamMap.get("page")) {
      nextPage = +this.route.snapshot.queryParamMap.get("page");
      this.size = +this.route.snapshot.queryParamMap.get("size");
      this.getServicesByPage(0, nextPage);
    }else{
      this.getServicesByPage(0, nextPage);
    }
    
    this.querySub = this.route.queryParams.subscribe(() => {
      if (this.route.snapshot.queryParamMap.get("page")) {
        nextPage = +this.route.snapshot.queryParamMap.get("page");
        this.size = +this.route.snapshot.queryParamMap.get("size");
        this.getServicesByPage(0, nextPage);
      }else{
        this.getServicesByPage(0, nextPage);
      }
    });
  };

  getServicesByPage(id: number, nextPage: number) {
    this.doctorService.getServicesByPage(id, nextPage, this.size).subscribe((page) => {
      this.page = page;
      this.serviceList = this.page.content;
      this.serviceListOrdinata = this.serviceList;
      this.spinner.hide();
    },
      (_) => {
        console.log("Get Orde Failed");
        this.spinner.hide();
      })
  };

  pageChangeEvent(event) {
    const offset = (event.pageIndex + 1 - 1) * event.pageSize;
    this.serviceListOrdinata = this.serviceList.slice(offset).slice(0, event.pageSize);
  }

  open(src): void {
    let albums = [];
    const album = {
      src: src
    }
    albums.push(album)
    // open lightbox
    this._lightbox.open(albums);
  }


  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  removeCategory(service) {
    const dialogRef = this.dialog.open(DialogCategoryModal, {
      width: "600px",
      height: "200px",
      data: { service: service },
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        let nextPage = 1;
        this.getServicesByPage(0, nextPage);
      }
    });
  }

}


@Component({
  selector: "dialog-category-modal",
  templateUrl: "./dialog-category-modal.html",
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class DialogCategoryModal {
  serviceList;
  serviceListOrdinata;
  pageSize = 5;
  pageSizeOptions = [5, 10, 20, 30, 40, 50];
  pageLength: number;
  private inputSubject: Subject<string> = new Subject<string>();
  constructor(
    public dialogRef: MatDialogRef<DialogCategoryModal>,
    private spinner: NgxSpinnerService,
    private doctorService: DoctorServices,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.inputSubject.pipe(debounceTime(500)).subscribe(() => {
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onConfirm() {
    this.spinner.show();
    this.doctorService.deleteServiceById(this.data.service.serviceInShopId).toPromise().then((x) => {}).catch(err => {
      if(err.text.includes("cancellato")){
        this.dialogRef.close(true);
      }else{
        this.dialogRef.close(false);
      }
      this.spinner.hide();
    });

  }
}
